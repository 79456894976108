<div #content="pwContent" pwContent class="modernizedRiskAnalysisWrapper" *ngIf="hasLoadedHistoricRiskReturnData">
    <div class="currentColumn">
        <div class="modernizedHeader">
            <h3 class="acessibility"
                [innerHTML]="content['PWAsset_DetailsCard_Accordion_CurrentAssetAllocation'] | getFromAEM"></h3>
            <button 
                class="icon icon-question-mark-black" 
                (click)="selectSection('Risk-return mix analysis')"
                aria-label="Help Button">
            </button>
        </div>
        <div class="modernizedHistoricalRiskReturn">
            {{ content['PWAsset_DetailsCard_Accordion_CurrentHistoricalRiskReturn'] | getFromAEM }} 
            {{ '('+ currentAllocationHistoricRiskReturn.fromYear + '-' + currentAllocationHistoricRiskReturn.toYear + ')' }}
        </div>
        <div class='modernizedRiskReturnWrapper'>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{ currentAllocationHistoricRiskReturn.averageReturn + '%' }}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_CurrentAverageReturn'] | getFromAEM}}
                </span>
            </div>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{ currentAllocationHistoricRiskReturn.bestYearAverage + '%' }}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_CurrentBestYear'] | getFromAEM }} 
                    {{' (' + currentAllocationHistoricRiskReturn.bestYear + ')' }}
                </span>
            </div>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{ currentAllocationHistoricRiskReturn.worstYearAverage + '%'}}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_CurrentWorstYear'] | getFromAEM }}
                    {{ '(' + currentAllocationHistoricRiskReturn.worstYear + ')' }}
                </span>
            </div>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{ currentAllocationHistoricRiskReturn.percentYearsOfLoss + '%' }}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_CurrentYearsWithALoss'] | getFromAEM }}
                    {{ ' (' + currentAllocationHistoricRiskReturn.yearsWithLoss + ' of ' + currentAllocationHistoricRiskReturn.totalYears + ')' }}
                </span>
            </div>
        </div>
    </div>
    <div class="targetColumn">
        <div class="modernizedHeader"
            [innerHTML]="content['PWAsset_DetailsCard_Accordion_TargetAssetAllocation'] | getFromAEM">
        </div>
        <div class="modernizedHistoricalRiskReturn">
            {{ content['PWAsset_DetailsCard_Accordion_TargetHistoricalRiskReturn'] | getFromAEM }} 
            {{ '('+ currentAllocationHistoricRiskReturn.fromYear + '-' + currentAllocationHistoricRiskReturn.toYear + ')' }}
        </div>
        <div class='modernizedRiskReturnWrapper'>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{targetAllocationHistoricRiskReturn.averageReturn + '% '}}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_TargetAverageReturn'] | getFromAEM }}
                </span>
            </div>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{ targetAllocationHistoricRiskReturn.bestYearAverage + '%' }}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_TargetBestYear'] | getFromAEM }}
                    {{ ' ('+ targetAllocationHistoricRiskReturn.bestYear + ')' }}
                </span>
            </div>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{ targetAllocationHistoricRiskReturn.worstYearAverage + '%' }}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_TargetWorstYear'] | getFromAEM}}
                    {{ ' ('+ targetAllocationHistoricRiskReturn.worstYear + ')' }}
                </span>
            </div>
            <div class='boxItem'>
                <span class="modernizedAverageValues">
                    {{targetAllocationHistoricRiskReturn.percentYearsOfLoss + '%' }}
                </span>
                <span class="modernizedAverageContent">
                    {{ content['PWAsset_DetailsCard_Accordion_TargetYearsWithALoss'] | getFromAEM }}
                    {{ '(' + targetAllocationHistoricRiskReturn.yearsWithLoss + ' of ' +
                        targetAllocationHistoricRiskReturn.totalYears + ')' }}
                </span>
            </div>
        </div>
    </div>
</div>