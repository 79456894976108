import { Component, Input, HostListener, OnInit  } from '@angular/core';

@Component({
  selector: 'PW1-accordion',
  templateUrl: './custom-accordion.component.html',
  styleUrls: ['./custom-accordion.component.scss'],
})
export class CustomAccordionComponent implements OnInit {
  opened: boolean = false;
  @Input() title?: string;
  @Input() accountBalance?: number;
  isMobile: boolean = false;

  ngOnInit() {
    this.checkViewportWidth();
  }

  @HostListener('window:resize')
  onResize() {
    this.checkViewportWidth();
  }

  checkViewportWidth() {
    this.isMobile = window.innerWidth <= 500;
  }

  toggleState() {
    this.opened = !this.opened;
  }
}
