<c11n-card #content="pwContent" pwContent class="modernized-cost-card">
  <div cardHeading class="titleWrapper">
    <div class="titleWrapper__row">
      <h3
        *ngIf="!isHypotheticalData"
        class="accessibility"
        [innerHTML]="content['PWOverview_InvestmentCosts'] | getFromAEM"
      ></h3>
      <span
        *ngIf="isHypotheticalData"
        class="center-vertically"
        [innerHTML]="content['TesterTool_Overview_HypotheticalCostsCard_Title'] | getFromAEM"
      ></span>
      <p class="titleWrapper__title">
        <button
          class="icon icon-question-mark-black"
          (click)="selectSection('Investment costs')"
          aria-label="Investment Costs help button"
        ></button>
      </p>
      <button
        cardAction
        #cardAction
        class="navigateLink seeDetails"
        aria-label="Investment Costs see details"
        (click)="goToInvestmentCostsPage()"
      >
        <span [innerHTML]="content['PWOverview_InvestmentCosts_SeeDetails'] | getFromAEM"></span
        ><i class="icon icon-right-arrow-blue-modernized"></i>
      </button>
    </div>
  </div>
  <div cardBody *ngIf="hasLoadedChartData">
    <div [ngClass]="{ isHypothetical: isHypotheticalData }" class="modernizedContainer">
      <div class="cardModernizedChart">
        <bar-chart
          [chartData]="modernizedChartData"
          [industryChartId]="industryChartId"
          [vanguardChartId]="vanguardChartId"
          [yourCostsChartId]="yourCostsChartId"
          [isFullWidth]="false"
        ></bar-chart>
      </div>
      <div class="investment-costs-disclaimer">
        <!-- TODO : update the AEM content key once the AEM changes are available in PROD -->
        <p>*As of December 2022</p>
      </div>
    </div>
  </div>
</c11n-card>
