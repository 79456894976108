import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PortfolioDataService } from 'src/app/services/portfolio-data-service/portfolio-data.service';
import { Observable, Subject } from 'rxjs';
import { CustomUserModernizationService } from 'src/app/services/custom-user-modernization/custom-user-modernization.service';
@Component({
  selector: 'PW1-secure-site-header-container',
  templateUrl: './secure-site-header-container.component.html',
  styleUrls: ['./secure-site-header-container.component.scss'],
})
export class SecureSiteHeaderContainerComponent implements OnInit, OnDestroy {
  displayGreetingHeader: Observable<boolean>;
  private destroySource: Subject<boolean> = new Subject<boolean>();
  @Input() isFocusPanelOpen: boolean;
  @Output() openFocusPanel: EventEmitter<any> = new EventEmitter();

  constructor(
    public router: Router,
    private portfolioDataService: PortfolioDataService,
    private userModernizationService: CustomUserModernizationService,
  ) {}

  ngOnInit() {
    this.displayGreetingHeader = this.userModernizationService.isModernizedUser;    
  }

  checkFocusPanelOptions() {
    return (
      !this.isFocusPanelOpen &&
      !(this.router.url.includes('/tester-tool') || this.router.url.startsWith('/hypothetical') || this.router.url.includes('portfolio-health-check'))
    );
  }

  ngOnDestroy(): void {
    this.destroySource.complete();
  }
}
