<div *ngIf='loaded' #content="pwContent" pwContent class="tableComponentWrapper">
  <PW1-alert-notifications [pageName]="'/stock-analysis/international-regions'"></PW1-alert-notifications>
  <div class="tableHeaderWrapper">
    <div class="titleAndExport">
      <div class='tableTitle'>
        <h2 class="accessibility">
          <span [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions'] | getFromAEM"></span>
          <button id="helpInternationalRegions" class="icon icon-question-mark-black"
            (click)="openInternationalRegionsDrawer()" aria-label="Help Button"></button>
          <PW1-export-to-csv *ngIf="viewByHoldings" [parsableArray]="generateTableDataByHoldingsModernized()"
            [fileName]="'StockInternationalRegionsData'" [modernized]="true">
          </PW1-export-to-csv>
        </h2>
      </div>
    </div>
    <PW1-table-view-controls (tableViewChanged)="changeTableView($event)"></PW1-table-view-controls>
  </div>

  <ng-container *ngIf="loaded && !viewByHoldings">
    <table class="c11n-table c11n-table--medium">
      <thead>
        <tr class="c11n-table__tr" scope="row">
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">
            <strong>{{content['PWStocks_DetailsCard_InternationalRegions_Headers_Market'] | getFromAEM}}</strong>
          </th>
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">
            <strong>{{content['PWStocks_DetailsCard_InternationalRegions_Headers_StockPortfolio'] | getFromAEM}}</strong>
          </th>
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">
            <strong>{{content['PWStocks_DetailsCard_InternationalRegions_Headers_StockMarket'] | getFromAEM}}</strong>
          </th>
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">
            <strong>{{content['PWStocks_DetailsCard_InternationalRegions_Headers_Difference'] | getFromAEM}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="c11n-table__tr" *ngFor="let tableRow of tableData" scope="row"
          [ngClass]="{'totalRow': tableRow.size === 'Total', 'rowHeader': tableRow.yourStock === ''}">
          <td scope="col" class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left">{{tableRow.market}}
          </td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.yourStock}}</td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.stockOutsideUS}}</td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.difference}}</td>
        </tr>
        <tr class="c11n-table__tr" scope="row" *ngFor="let totalRow of totalData" class="totalRow">
          <td scope="col" class="c11n-table__td c11n-table__td--align-left"><strong>{{totalRow.market}}</strong></td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right"><strong>{{totalRow.yourStock}}</strong>
          </td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">
            <strong>{{totalRow.stockOutsideUS}}</strong></td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right"><strong>{{totalRow.difference}}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="loaded && viewByHoldings">
    <PW1-holdings-table [tableData]="holdingsTableDataModernized"></PW1-holdings-table>
  </ng-container>
</div>