<section #content="pwContent" pwContent id="asset-analysis-content" tabindex="-1">
  <PW1-hypothetical-card
    [backButtonLabel]="content['TesterTool_HypotheticalAssetPage_BackToOverview'] | getFromAEM"
    [title]="content['TesterTool_HypotheticalAssetPage_Title'] | getFromAEM"
    sectionLabel="Asset mix"
  >
    <ng-container>
      <account-group-assets></account-group-assets>
      <div class="side-by-side-container">
        <div class="wrapper asset-allocation">
          <asset-mix-dashboard
            [isFullWidth]="false"
            [isHypotheticalData]="false"
            [actualAssetAllocationChartId]="'modernizedTesterToolAssetPageActualAssetAllocationId'"
            [targetAssetAllocationChartId]="'modernizedTesterToolAssetPageTargetAssetAllocationId'"
          >
          </asset-mix-dashboard>
        </div>
        <div class="wrapper hypothetical-allocation">
          <asset-mix-dashboard
            [isFullWidth]="false"
            [isHypotheticalData]="true"
            [actualAssetAllocationChartId]="
              'modernizedTesterToolHypotheticalAssetPageActualAssetAllocationId'
            "
            [targetAssetAllocationChartId]="
              'modernizedTesterToolHypotheticalAssetPageTargetAssetAllocationId'
            "
          >
          </asset-mix-dashboard>
        </div>
      </div>
      <div class="side-by-side-container">
        <hypothetical-asset-allocation-holdings
          [chartData]="chartData"
          [hypotheticalData]="hypotheticalChartData"
        ></hypothetical-asset-allocation-holdings>
      </div>
      <div class="side-by-side-container">
        <div class="wrapper asset-allocation">
          <h3
            class="header"
            [innerHTML]="content['TesterTool_HypotheticalAssetPage_CurrentRiskReturn'] | getFromAEM"
          ></h3>
          <div class="riskAnalysisWrapper" *ngIf="hasLoadedHistoricRiskReturnData">
            <div class="currentColumn">
              <table role="presentation" class="table">
                <tr class="header">
                  <th>
                  {{ content['TesterTool_HypotheticalAssetPage_CurrentHistoricalRiskReturn'] | getFromAEM }} 
                  {{' (' + currentAllocationHistoricRiskReturn.fromYear + '-' + currentAllocationHistoricRiskReturn.toYear +')'}}
                  </th>
                  <th></th>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentAverageReturn'] | getFromAEM }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="currentAllocationHistoricRiskReturn.averageReturn + '%'"
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentBestYear'] | getFromAEM }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturn.bestYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturn.bestYear +
                      ')'
                    "
                  >
                </td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentWorstYear'] | getFromAEM }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturn.worstYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturn.worstYear +
                      ')'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_CurrentYearsWithALoss'] | getFromAEM }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturn.yearsWithLoss +
                      ' of ' +
                      currentAllocationHistoricRiskReturn.totalYears +
                      ' (' +
                      currentAllocationHistoricRiskReturn.percentYearsOfLoss +
                      '%' +
                      ')'
                    "
                  ></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="wrapper hypothetical-allocation">
          <h3
            class="header"
            [innerHTML]="content['TesterTool_HypotheticalAssetPage_HypotheticalRiskReturn'] | getFromAEM"
          ></h3>
          <div class="riskAnalysisWrapper" *ngIf="hasLoadedHistoricRiskReturnDataHypothetical">
            <div class="currentColumn">
              <table role="presentation" class="table hypothetical">
                <tr class="header">
                  <th>
                    {{ content['TesterTool_HypotheticalAssetPage_CurrentHistoricalRiskReturn'] | getFromAEM }} 
                    {{' (' + currentAllocationHistoricRiskReturnHypothetical.fromYear + '-' + currentAllocationHistoricRiskReturnHypothetical.toYear +')'}}
                  </th>
                  <th></th>
                </tr>
                <tr class="table-row">
                  <td>
                    {{ content['TesterTool_HypotheticalAssetPage_HypotheticalAverageReturn'] | getFromAEM }}
                  </td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.averageReturn + '%'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_HypotheticalBestYear'] | getFromAEM }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.bestYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturnHypothetical.bestYear +
                      ')'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>{{ content['TesterTool_HypotheticalAssetPage_HypotheticalWorstYear'] | getFromAEM }}</td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.worstYearAverage +
                      '%' +
                      ' (' +
                      currentAllocationHistoricRiskReturnHypothetical.worstYear +
                      ')'
                    "
                  ></td>
                </tr>
                <tr class="table-row">
                  <td>
                    {{ content['TesterTool_HypotheticalAssetPage_HypotheticalYearsWithALoss'] | getFromAEM }}
                  </td>
                  <td
                    class="table-data"
                    [innerHTML]="
                      currentAllocationHistoricRiskReturnHypothetical.yearsWithLoss +
                      ' of ' +
                      currentAllocationHistoricRiskReturnHypothetical.totalYears +
                      ' (' +
                      currentAllocationHistoricRiskReturnHypothetical.percentYearsOfLoss +
                      '%' +
                      ')'
                    "
                  ></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </PW1-hypothetical-card>
</section>
