import { Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { MARKET_CAP_CONSTANTS } from '../../../models/chart.constants';
import { HoldingsTableBuilderService } from '../../../services/holdings-table-builder/holdings-table-builder.service';
import {
  CategorizationObject,
  HoldingsTableCategorization,
  HoldingsTableData,
} from '../../../models/holdings-table';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { LoggerCode } from '../../../../models/logger';
import { LoggerService } from '../../../services/logger/logger.service';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'market-cap-table',
  templateUrl: './market-cap-table.component.html',
  styleUrls: ['./market-cap-table.component.scss'],
})
export class MarketCapTableComponent implements OnInit, AfterViewInit {
  @Input() chartData;

  tableData = [];
  holdingsTableDataModernized: HoldingsTableData;
  holdingsTotal: number = 0;

  loaded = false;
  viewByHoldings = false;
  portfolioLargeCap = MARKET_CAP_CONSTANTS.ZERO;
  portfolioMidCap = MARKET_CAP_CONSTANTS.ZERO;
  portfolioSmallCap = MARKET_CAP_CONSTANTS.ZERO;
  portfolioTotal: number;
  portfolioUncategorized: number;
  marketLargeCap = MARKET_CAP_CONSTANTS.ZERO;
  marketMidCap = MARKET_CAP_CONSTANTS.ZERO;
  marketSmallCap = MARKET_CAP_CONSTANTS.ZERO;
  marketTotal: number;
  marketUncategorized: number;
  private lgDifference: any;
  private mdDifference: any;
  private smDifference: any;
  private uncategorizedDifference: any;

  
  tabCount: number = 0;

  constructor(
    private holdingsTableBuilderService: HoldingsTableBuilderService,
    private router: Router,
    public drawerTrackerService: DrawerTrackerService,
    private logger: LoggerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    if (this.chartData) {
      this.setTableData();
      this.getHoldingsDataModernized();
    }
  }

  ngAfterViewInit(): void {
    this.addTabOutDrawerListener();
  }

  addTabOutDrawerListener(): void {
    const drawerElement = document.getElementById('rt-drawer');
    if (drawerElement) {
      drawerElement.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          this.tabCount++;
          if (this.tabCount > 3) {
            this.handleDrawerClose();
            this.tabCount = 0; // Reset the tab count
          }
        }
      });

      drawerElement.addEventListener('focusout', () => {
        setTimeout(() => {
          if (!drawerElement.contains(document.activeElement)) {
            this.handleDrawerClose();
            this.tabCount = 0; // Reset the tab count
          }
        }, 0);
      });
      this.drawerTrackerService.closeDrawer();
    }
  }

  setFocusToHelpMarketCapitalization(): void {
    const helpMarketCapitalization = document.getElementById('helpMarketCapitalization');
    if (helpMarketCapitalization) {
      setTimeout(() => {
        (helpMarketCapitalization as HTMLElement).focus();
      }, 750);
    }
  }

  handleDrawerClose(): void {
    this.setFocusToHelpMarketCapitalization();
    const drawerElement = document.getElementById('rt-drawer');
    if (drawerElement) {
      this.drawerTrackerService.closeDrawer();
    }
  }


  setPortfolioData() {
    let usStockMarketCap = this.chartData.stockMarketCap.usStockMarketCap;
    this.marketLargeCap = usStockMarketCap ? usStockMarketCap.large : MARKET_CAP_CONSTANTS.ZERO;
    this.marketMidCap = usStockMarketCap ? usStockMarketCap.medium : MARKET_CAP_CONSTANTS.ZERO;
    this.marketSmallCap = usStockMarketCap ? usStockMarketCap.small : MARKET_CAP_CONSTANTS.ZERO;
    this.marketUncategorized = usStockMarketCap
      ? usStockMarketCap.uncategorized
      : MARKET_CAP_CONSTANTS.ZERO;
    this.marketTotal =
      this.marketLargeCap + this.marketMidCap + this.marketSmallCap + this.marketUncategorized;
  }
  setTableData() {
    this.portfolioLargeCap = this.chartData.stockMarketCap.chart.large;
    this.portfolioMidCap = this.chartData.stockMarketCap.chart.medium;
    this.portfolioSmallCap = this.chartData.stockMarketCap.chart.small;
    this.portfolioUncategorized = this.chartData.stockMarketCap.chart.uncategorized;
    this.portfolioTotal = Number(
      (
        this.portfolioLargeCap +
        this.portfolioMidCap +
        this.portfolioSmallCap +
        this.portfolioUncategorized
      ).toFixed(0),
    );

    this.lgDifference = this.chartData.stockMarketCap.difference.large;
    this.mdDifference = this.chartData.stockMarketCap.difference.medium;
    this.smDifference = this.chartData.stockMarketCap.difference.small;
    this.uncategorizedDifference = this.chartData.stockMarketCap.difference.uncategorized;

    this.setPortfolioData();
    this.tableData = [
      {
        size: 'Large cap',
        yourStock: this.portfolioLargeCap + '%',
        market: this.marketLargeCap + '%',
        difference: this.lgDifference + '%',
      },
      {
        size: 'Mid cap',
        yourStock: this.portfolioMidCap + '%',
        market: this.marketMidCap + '%',
        difference: this.mdDifference + '%',
      },
      {
        size: 'Small cap',
        yourStock: this.portfolioSmallCap + '%',
        market: this.marketSmallCap + '%',
        difference: this.smDifference + '%',
      },
    ];

    if (this.portfolioUncategorized > 0) {
      this.tableData.push({
        size: 'Uncategorized',
        yourStock: this.portfolioUncategorized + '%',
        market: this.marketUncategorized + '%',
        difference: this.uncategorizedDifference + '%',
      });
    }

    this.tableData.push({
      size: 'Total',
      yourStock: this.portfolioTotal.toFixed(0) + '%',
      market: this.marketTotal.toFixed(0) + '%',
    });
    this.loaded = true;
  }

  getHoldingsDataModernized() {
    this.holdingsTableDataModernized = this.generateTableDataByHoldingsModernized();
  }

  generateTableDataByHoldingsModernized(): HoldingsTableData {
    try {
      const applicableBalance: number = this.chartData?.stockMarketCap?.applicableBalance;
      const categorizationObjects: CategorizationObject[] = [
        { title: 'Large capitalization stocks & stock funds', categorization: 'large' },
        { title: 'Medium capitalization stocks & stock funds', categorization: 'medium' },
        { title: 'Small capitalization stocks & stock funds', categorization: 'small' },
        {
          title: 'Uncategorized capitalization stocks & stock funds',
          categorization: 'uncategorized',
        },
      ];
      const holdingsDataByCategorizations: HoldingsTableCategorization[] =
        this.holdingsTableBuilderService.getHoldingsByCategorizations(
          this.chartData.stockMarketCap,
          categorizationObjects,
        );

      return {
        categorizations: holdingsDataByCategorizations,
        holdingsTotal: applicableBalance,
      };
    } catch (e) {
      this.logger.error(
        { message: 'Failed to generate holding details ' + e.message },
        LoggerCode.GENERATE_HOLDINGS_DETAILS,
      );
      return {
        categorizations: [],
        holdingsTotal: 0,
      };
    }
  }

  changeTableView(val: boolean) {
    this.viewByHoldings = val;
    if (val) {
      this.adobeAnalyticsService.clickedOnHoldingsDetails();
    }
  }

  navigateToLearnMorePage() {
    this.adobeAnalyticsService.clickedOnLearnMore('/learn-more/market-capitalization');
    this.router.navigate(['/learn-more/market-capitalization']);
  }
  openMarketCapDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.MARKET_CAPITALIZATION);
  }
}
