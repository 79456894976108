import { Component, Inject, OnInit } from '@angular/core';
import { ChartDataService } from '../../../services/chart-data-service/chart-data.service';
import { ChartData } from '../../../models/chart-models/chart-data';
import { LegendLine } from '../../../models/chart-models/legend-line';
import { DOCUMENT } from '@angular/common';
import { HypotheticalChartDataService } from '../../../services/hypothetical-chart-data-service/hypothetical-chart-data.service';
import { Observable } from 'rxjs';

const ZERO_INDEX = 0;

@Component({
  selector: 'hypothetical-stock-analysis',
  templateUrl: './hypothetical-stock-analysis.component.html',
  styleUrls: ['./hypothetical-stock-analysis.component.scss'],
})
export class HypotheticalStockAnalysisComponent implements OnInit {
  chartData: ChartData;
  hypotheticalChartData: ChartData;

  stockChartId = 'stockChartId';
  hypotheticalStockChartId = 'hypotheticalStockChartId';

  legendHeader: string;
  hypotheticalLegendHeader: string;

  hasLoadedChartData = false;
  hasLoadedHypotheticalChartData = false;

  legendArray: Array<LegendLine>;
  hypotheticalLegendArray: Array<LegendLine>;

  legendArrayModern: Array<LegendLine>;
  hypotheticalLegendArrayModern: Array<LegendLine>;

  modernizedEnabled: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private chartService: ChartDataService,
    private hypotheticalChartDataService: HypotheticalChartDataService,
  ) {}

  ngOnInit() {
    this.setupCurrentStockAnalysisChart();
    this.setupHypotheticalStockAnalysisChart();
  }

  setupCurrentStockAnalysisChart() {
    this.legendHeader = "Stocks (domestic vs. int'l)";
    this.chartService.getStockAnalysisChartPayload().subscribe((data) => {
      if (data.labelDetails.length !== ZERO_INDEX) {
        this.chartData = data;
        this.legendArray = this.chartService.getStockAnalysisCardLegend();
        this.legendArrayModern = this.chartService.getStockAnalysisCardLegendModern();
        this.hasLoadedChartData = true;
      }
    });
  }

  setupHypotheticalStockAnalysisChart() {
    this.hypotheticalLegendHeader = "Hypothetical stocks (domestic vs. int'l)";
    this.hypotheticalChartDataService.getStockAnalysisChartPayload().subscribe((data) => {
      if (data.labelDetails.length !== ZERO_INDEX) {
        this.hypotheticalChartData = data;
        this.hypotheticalLegendArray =
          this.hypotheticalChartDataService.getStockAnalysisCardLegend();
        this.hypotheticalLegendArrayModern =
          this.hypotheticalChartDataService.getStockAnalysisCardLegendModern();
        this.hasLoadedHypotheticalChartData = true;
      }
    });
  }

}
