<a 
  #content="pwContent" pwContent 
  id="{{fileName}}" 
  class="export modernized" 
  (click)="exportToCsv()"
  [attr.href]="getCSV()" 
  [attr.download]="getDownload()" 
  aria-describedby="export-description"
  >
  {{content['PWExportData'] | getFromAEM}}
  <span class="icon icon-download"></span>
</a>
<span id="export-description" aria-live="polite" aria-atomic="true" class="sr-only">
  Export data for {{ fileName }}
</span>