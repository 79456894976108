<div *ngIf="chartData">
  <c11n-tabs
    activeItem="credit-quality"
    [hasAnimation]="true"
    (activeItemChange)="updateUrl($event)"
  >
    <c11n-tab-panel labelText="Credit Quality" role="link" id="credit-quality">
      <PW1-hypothetical-credit-quality-table
        [chartData]="chartData"
        [hypotheticalData]="hypotheticalData"
      ></PW1-hypothetical-credit-quality-table>
    </c11n-tab-panel>
    <c11n-tab-panel
      labelText="Interest Rate Sensitivity"
      role="link"
      id="interest-rate-sensitivity"
    >
      <PW1-hypothetical-effective-maturity-table
        [chartData]="chartData"
        [hypotheticalData]="hypotheticalData"
      ></PW1-hypothetical-effective-maturity-table>
    </c11n-tab-panel>
    <c11n-tab-panel labelText="Bond 9-box style" role="link" id="nine-box">
      <PW1-hypothetical-nine-box-component
        [chartData]="chartData"
        [hypotheticalChartData]="hypotheticalData"
      ></PW1-hypothetical-nine-box-component>
    </c11n-tab-panel>
  </c11n-tabs>
</div>
