import { AssetTableCategorization, HoldingSubtotalCategorization } from 'src/app/models/holdings-table';
import { Component, OnInit, Input } from '@angular/core';
import { HoldingsViewHelperService } from '../../../../services/holdings-view-helper/holdings-view-helper.service';

@Component({
  selector: 'hypothetical-asset-allocation-holdings',
  templateUrl: './hypothetical-asset-allocation-holdings.component.html',
  styleUrls: ['./hypothetical-asset-allocation-holdings.component.scss'],
})
export class HypotheticalAssetAllocationHoldingsComponent implements OnInit {
  @Input() chartData;
  @Input() hypotheticalData;

  showHoldingsTable: boolean = false;

  tableDataByHoldings = [];
  tableDataByHoldingsHypothetical = [];

  holdingsTotal: number = 0;
  holdingsTotalHypothetical: number = 0;

  allTablesData: any[];

  constructor(private holdingsViewHelper: HoldingsViewHelperService) {}

  ngOnInit() {
    if (this.chartData && this.hypotheticalData) {
      this.setHoldingsData();
      this.setHoldingsDataHypothetical();
      this.allTablesData = this.getAllTables();
      console.log(this.allTablesData)
    }
  }

  toggleHoldingsView() {
    this.showHoldingsTable = !this.showHoldingsTable;
  }

  setHoldingsData() {
    const domesticStocks = this.getHoldingsForTableSection(
      ' Current U.S. stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.USStocks
    );
    const internationalStocks = this.getHoldingsForTableSection(
      'Current international stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.InternationalStocks,
    );
    const stockOthers = this.getHoldingsForTableSection(
      'Current other stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherStocks
    );
    const domesticBonds = this.getHoldingsForTableSection(
      'Current U.S. bonds & bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.USBonds
    );
    const internationalBonds = this.getHoldingsForTableSection(
      'Current international bonds & bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.InternationalBonds
    );
    const bondOthers = this.getHoldingsForTableSection(
      'Current other bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherBonds
    );
    const shortTermReserves = this.getHoldingsForTableSection(
      'Current short term reserves',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.ShortTermReserves
    );
    const others = this.getHoldingsForTableSection(
      'Current other asset types',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherTypes
    );

    this.tableDataByHoldings = [
      domesticStocks,
      internationalStocks,
      stockOthers,
      domesticBonds,
      internationalBonds,
      bondOthers,
      shortTermReserves,
      others,
    ];

    let category = { chart: {} };
    category.chart = this.createCategoryArray(this.tableDataByHoldings);

    this.holdingsViewHelper.roundingHelper(this.tableDataByHoldings, category, 'asset');
    this.holdingsTotal = this.holdingsViewHelper.calculateTotal(this.tableDataByHoldings);
  }

  setHoldingsDataHypothetical() {
    const domesticStocks = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical U.S. stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.USStocks
    );
    const internationalStocks = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical international stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.InternationalStocks
    );
    const stockOthers = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical other stocks & stock funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherStocks
    );
    const domesticBonds = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical U.S. bonds & bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.USBonds
    );
    const internationalBonds = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical international bonds & bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.InternationalBonds
    );
    const bondOthers = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical other bond funds',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherBonds
    );
    const shortTermReserves = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical short term reserves',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.ShortTermReserves
    );
    const others = this.getHoldingsForTableSectionHypothetical(
      'Hypothetical other asset types',
      AssetTableCategorization.AssetAllocation,
      HoldingSubtotalCategorization.OtherTypes
    );

    this.tableDataByHoldingsHypothetical = [
      domesticStocks,
      internationalStocks,
      stockOthers,
      domesticBonds,
      internationalBonds,
      bondOthers,
      shortTermReserves,
      others,
    ];

    let category = { chart: {} };
    category.chart = this.createCategoryArray(this.tableDataByHoldingsHypothetical);

    this.holdingsViewHelper.roundingHelper(this.tableDataByHoldingsHypothetical, category, 'asset');
    this.holdingsTotalHypothetical = this.holdingsViewHelper.calculateTotal(
      this.tableDataByHoldingsHypothetical,
    );
  }

  getHoldingsForTableSection(sectionTitle, dataNode, categorization) {
    const holdingsForDomestic = this.holdingsViewHelper.getArrayOfHoldingsForSubtotalCategorization(
      this.chartData,
      dataNode,
      categorization,
    );
    const acctsForDomestic =
      this.holdingsViewHelper.getArrayOfAccountsForCategorization(holdingsForDomestic);

    let arrayOfAccounts = [];
    let categorizationSubtotal = 0;
    let categorizationPercentageSubTotal = 0;

    acctsForDomestic.forEach((accountName) => {
      arrayOfAccounts.push(this.holdingsViewHelper.getAccountNameObject(accountName));

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        holdingsForDomestic,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        const acctObject =
          this.holdingsViewHelper.getHoldingInformationWithoutDifferenceByPercentage(
            holding,
            categorization,
          );
        categorizationSubtotal += acctObject.value;
        categorizationPercentageSubTotal += acctObject.percentage;
        arrayOfAccounts.push(acctObject);
      });
    });

    if (arrayOfAccounts.length > 0) {
      arrayOfAccounts.push(
        this.holdingsViewHelper.getSubtotalObjectWithoutDifference(
          categorizationSubtotal,
          categorizationPercentageSubTotal,
        ),
      );
    }

    return {
      header: sectionTitle,
      accounts: arrayOfAccounts,
      dataNode: dataNode,
      categorization: categorization,
    };
  }

  getHoldingsForTableSectionHypothetical(
    sectionTitle,
    dataNode,
    categorization,
  ) {
    const holdingsForDomestic = this.holdingsViewHelper.getArrayOfHoldingsForSubtotalCategorization(
      this.hypotheticalData,
      dataNode,
      categorization,
    );
    const acctsForDomestic =
      this.holdingsViewHelper.getArrayOfAccountsForCategorization(holdingsForDomestic);

    let arrayOfAccounts = [];
    let categorizationSubtotal = 0;
    let categorizationPercentageSubTotal = 0;

    acctsForDomestic.forEach((accountName) => {
      arrayOfAccounts.push(this.holdingsViewHelper.getAccountNameObject(accountName));

      let holdingsArrayForAccount = this.holdingsViewHelper.getHoldingsFromSpecificAccount(
        holdingsForDomestic,
        accountName,
      );

      holdingsArrayForAccount.forEach((holding) => {
        const acctObject =
          this.holdingsViewHelper.getHoldingInformationWithoutDifferenceByPercentage(
            holding,
            categorization,
          );
        categorizationSubtotal += acctObject.value;
        categorizationPercentageSubTotal += acctObject.percentage;
        arrayOfAccounts.push(acctObject);
      });
    });

    if (arrayOfAccounts.length > 0) {
      arrayOfAccounts.push(
        this.holdingsViewHelper.getSubtotalObjectWithoutDifference(
          categorizationSubtotal,
          categorizationPercentageSubTotal,
        ),
      );
    }

    return {
      header: sectionTitle,
      accounts: arrayOfAccounts,
      dataNode: dataNode,
      categorization: categorization,
    };
  }

  getAllTables() {
    const unfiltered = [
      {
        actual: this.getTableForActual(AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.USStocks),
        hypothetical: this.getTableForHypothetical(AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.USStocks),
      },
      {
        actual: this.getTableForActual(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.InternationalStocks),
        hypothetical: this.getTableForHypothetical(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.InternationalStocks),
      },
      {
        actual: this.getTableForActual(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.OtherStocks),
        hypothetical: this.getTableForHypothetical(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.OtherStocks),
      },
      {
        actual: this.getTableForActual(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.USBonds),
        hypothetical: this.getTableForHypothetical(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.USBonds),
      },
      {
        actual: this.getTableForActual(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.InternationalBonds),
        hypothetical: this.getTableForHypothetical(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.InternationalBonds),
      },
      {
        actual: this.getTableForActual(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.OtherBonds),
        hypothetical: this.getTableForHypothetical(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.OtherBonds),
      },
      {
        actual: this.getTableForActual(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.ShortTermReserves),
        hypothetical: this.getTableForHypothetical(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.ShortTermReserves),
      },
      {
        actual: this.getTableForActual(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.OtherTypes),
        hypothetical: this.getTableForHypothetical(      AssetTableCategorization.AssetAllocation,
          HoldingSubtotalCategorization.OtherTypes),
      },
    ];

    return unfiltered.filter((category) => {
      return category.actual.accounts.length > 0 || category.hypothetical.accounts.length > 0;
    });
  }

  getTableForActual(dataNode, categorization) {
    const filteredTableData = this.tableDataByHoldings.filter((tableData) => {
      return (
        tableData && tableData.categorization === categorization && tableData.dataNode === dataNode
      );
    });

    return filteredTableData[0];
  }

  getTableForHypothetical(dataNode, categorization) {
    const filteredTableData = this.tableDataByHoldingsHypothetical.filter((tableData) => {
      return (
        tableData && tableData.categorization === categorization && tableData.dataNode === dataNode
      );
    });

    return filteredTableData[0];
  }

  createCategoryArray(arr: any) {
    let newArr = [];
    arr = arr.filter(function (e) {
      return e;
    });
    let total = 0;
    for (let i: number = 0; i < arr.length; i++) {
      for (let j: number = 0; j < arr[i].accounts.length; j++) {
        if (arr[i].accounts[j].name === 'Subtotal') {
          let roundedVal = Number(
            Number(+(Math.round(Number(arr[i].accounts[j].percentage + 'e+2')) + 'e-2')).toFixed(2),
          );
          total += roundedVal;
          newArr.push(roundedVal);
        }
      }
    }
    const difference = 100 - total;
    if (difference >= 0.01 || difference <= -0.01) {
      newArr[0] += difference;
    }
    return newArr;
  }
}
