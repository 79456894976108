import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { RiskAnalysisType } from '../../models/chart-data-response-models/risk-analysis-type';
import { DrawerTrackerService } from '@vanguard/pfx-components';

@Component({
  selector: 'risk-return-analysis',
  templateUrl: './risk-return-analysis.component.html',
  styleUrls: ['./risk-return-analysis.component.scss'],
})
export class RiskReturnAnalysisComponent implements OnInit {
  // Current Allocation Historic Risk/Return response
  currentAllocationHistoricRiskReturn: RiskAnalysisType;

  // Target Allocation Historic Risk/Return response
  targetAllocationHistoricRiskReturn: RiskAnalysisType;

  hasLoadedHistoricRiskReturnData = false;

  constructor(
    private portfolioDataService: PortfolioDataService,
    public drawerTrackerService: DrawerTrackerService,
  ) {}

  ngOnInit() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data.riskReturnAnalysisResponse) {
        this.currentAllocationHistoricRiskReturn =
          data.riskReturnAnalysisResponse.currentAllocationRiskAnalysis;
        this.targetAllocationHistoricRiskReturn =
          data.riskReturnAnalysisResponse.targetAllocationRiskAnalysis;
        this.hasLoadedHistoricRiskReturnData = true;
      }
    });
  }

  selectSection(sectionLabel: string) {
    this.drawerTrackerService.setSelectSection(sectionLabel);
  }
}
