<div class="setTargetManuallyWrapper" #content="pwContent" pwContent>
    <div class="svg-wrapper">
        <svg id="stock-svg-bar-manual" class="assets-svg" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="100%" height="100%" style="fill:#1ca29c" />
            <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)" />
            <pattern id="stripey-lines" width="10" height="10" patternUnits="userSpaceOnUse"
                patternTransform="rotate(45 0 0)">
                <line x1="0" y1="0" x2="0" y2="10" style="stroke:white" stroke-width="3px"></line>
            </pattern>
            <mask id="mask-for-patterned-bars">
                <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)"></rect>
            </mask>
        </svg>
        <svg id="bond-svg-bar-manual" class="assets-svg" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="100%" height="100%" style="fill:#bf8200" />
            <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)" />
            <pattern id="stripey-lines" width="10" height="10" patternUnits="userSpaceOnUse"
                patternTransform="rotate(45 0 0)">
                <line x1="0" y1="0" x2="0" y2="10" style="stroke:white" stroke-width="3px"></line>
            </pattern>
            <mask id="mask-for-patterned-bars">
                <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)"></rect>
            </mask>
        </svg>
        <svg id="short-term-reserves-svg-bar-manual" class="assets-svg" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="100%" height="100%" style="fill:#545454" />
            <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)" />
            <pattern id="stripey-lines" width="10" height="10" patternUnits="userSpaceOnUse"
                patternTransform="rotate(45 0 0)">
                <line x1="0" y1="0" x2="0" y2="10" style="stroke:white" stroke-width="3px"></line>
            </pattern>
            <mask id="mask-for-patterned-bars">
                <rect x="0" y="0" width="100%" height="100%" fill="url(#stripey-lines)"></rect>
            </mask>
        </svg>
    </div>
    <ng-container [formGroup]="assetFormArray.controls[0]">
        <div class="rowLabel">
            <svg height="12" width="12">
                <circle cx="6" cy="6" r="6" attr.fill="{{targetAssetMixChartData.targetAssetTypes[0].color}}" />
            </svg>
            <span>{{targetAssetMixChartData.targetAssetTypes[0].label}}</span>
            <c11n-input [size]="'medium'" [hasError]="(errorState | async)">
                <input c11nInput type="number" id="stock-input-manual" min="0" max="100" required
                    name="stock-input-manual" formControlName="stockControl" (change)="validateNumber($event)"
                    [placeholder]=0 aria-describedby="'Stock input field'">
            </c11n-input>
        </div>
        <div class="rowLabel">
            <svg height="12" width="12">
                <circle cx="6" cy="6" r="6" attr.fill="{{targetAssetMixChartData.targetAssetTypes[1].color}}" />
            </svg>
            <span>{{targetAssetMixChartData.targetAssetTypes[1].label}}</span>
            <c11n-input [size]="'medium'" [hasError]="(errorState | async)">
                <input c11nInput type="number" id="bond-input-manual" min="0" max="100" required
                    name="bond-input-manual" formControlName="bondControl" (change)="validateNumber($event)"
                    [placeholder]=0 aria-describedby="'Bond input field'">
            </c11n-input>
        </div>
        <div class="rowLabel">
            <svg height="12" width="12">
                <circle cx="6" cy="6" r="6" attr.fill="{{targetAssetMixChartData.targetAssetTypes[2].color}}" />
            </svg>
            <span>{{targetAssetMixChartData.targetAssetTypes[2].label}}</span>
            <c11n-input [size]="'medium'" [hasError]="(errorState | async)">
                <input c11nInput type="number" id="short-term-reserves-input-manual" min="0" max="100" required
                    (change)="validateNumber($event)" name="short-term-reserves-input-manual"
                    formControlName="shortTermReserveControl" [placeholder]=0
                    aria-describedby="'Short term reserves input field'">
            </c11n-input>
        </div>
        <c11n-banner *ngIf="(errorState | async)" [size]="'medium'" [hasCloseControl]="false" [variant]="'warning'"
            [headingText]="content['PWSetTargetAssetMix_ErrorMessage'] | getFromAEM"></c11n-banner>
    </ng-container>
</div>