import { NgModule } from '@angular/core';
import { DomainHelperPipe } from './domain-helper/domain-helper.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { AEMLinkPipe } from './aemlink/aem-link.pipe';
import { GetFromAEMPipe } from './get-from-aem/get-from-aem.pipe';

@NgModule({
  declarations: [DomainHelperPipe, SafeHtmlPipe, AEMLinkPipe, GetFromAEMPipe],
  exports: [DomainHelperPipe, SafeHtmlPipe, AEMLinkPipe, GetFromAEMPipe],
})
export class SharedPipesModule {}
