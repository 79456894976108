<div *ngIf='loaded' #content="pwContent" pwContent>
    <div class="tableHeaderWrapper">
        <div class="spacer"></div>
        <PW1-table-view-controls (tableViewChanged)="changeTableView($event)"></PW1-table-view-controls>
    </div>
    <ng-container *ngIf="loaded && loadedHypothetical && !viewByHoldings">
        <div class="side-by-side-tables">
            <div class="table-wrapper">
                <h3 [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_InternationalRegions_Current'] | getFromAEM"></h3>
                <table role="presentation" class="table holdingsTable">
                    <tr>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_Market'] | getFromAEM"></th>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_StockPortfolio'] | getFromAEM"></th>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_StockMarket'] | getFromAEM"></th>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_Difference'] | getFromAEM"></th>
                    </tr>
                    <tr *ngFor="let tableRow of tableData" [ngClass]="{'totalRow': tableRow.size === 'Total', 'rowHeader': tableRow.yourStock === ''}">
                        <td>{{tableRow.market}}</td>
                        <td>{{tableRow.yourStock}}</td>
                        <td>{{tableRow.stockOutsideUS}}</td>
                        <td>{{tableRow.difference}}</td>
                    </tr>
                    <tr *ngFor="let totalRow of totalData" class="totalRow">
                        <td>{{totalRow.market}}</td>
                        <td>{{totalRow.yourStock}}</td>
                        <td>{{totalRow.stockOutsideUS}}</td>
                        <td>{{totalRow.difference}}</td>
                    </tr>
                </table>
            </div>
            <div class="table-wrapper">
                <h3 [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_InternationalRegions_Hypothetical'] | getFromAEM"></h3>
                <table role="presentation" class="table holdingsTable hypothetical">
                    <tr>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_Market'] | getFromAEM"></th>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_StockPortfolio'] | getFromAEM"></th>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_StockMarket'] | getFromAEM"></th>
                        <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_Difference'] | getFromAEM"></th>
                    </tr>
                    <tr *ngFor="let tableRow of tableDataHypothetical" [ngClass]="{'totalRow': tableRow.size === 'Total', 'rowHeader': tableRow.yourStock === ''}">
                        <td>{{tableRow.market}}</td>
                        <td>{{tableRow.yourStock}}</td>
                        <td>{{tableRow.stockOutsideUS}}</td>
                        <td>{{tableRow.difference}}</td>
                    </tr>
                    <tr *ngFor="let totalRow of totalDataHypothetical" class="totalRow">
                        <td>{{totalRow.market}}</td>
                        <td>{{totalRow.yourStock}}</td>
                        <td>{{totalRow.stockOutsideUS}}</td>
                        <td>{{totalRow.difference}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="loaded && loadedHypothetical && viewByHoldings">
        <ng-container *ngFor="let tableData of holdingsTablesData; let last = last">
            <div class="side-by-side-tables-categories" [ngClass]="{ last: last }"  *ngIf="tableData.actual.accounts.length > 0 || tableData.hypothetical.accounts.length > 0">
                <div class="actual-side">
                    <h3>{{tableData.actual.header}}</h3>
                    <table role="presentation" class="table holdingsTable" *ngIf="tableData.actual.accounts.length > 0">
                        <ng-container [ngTemplateOutlet]="holdingsTableHeaders"></ng-container>
                        <tr *ngFor="let acct of tableData.actual.accounts" [ngClass]="{'totalRow': acct.name === 'Subtotal', 'accountName': !acct.value && !acct.percentage}">
                            <td>{{acct.name}}</td>
                            <td *ngIf="acct.value">{{acct.value | currency}}</td>
                            <td *ngIf="acct.value">{{acct.percentage | percentFormatted: null: acct.value}}</td>
                        </tr>
                    </table>
                    <div *ngIf="!tableData.actual.accounts.length > 0">
                        <p><em [innerText]="content['TesterTool_HypotheticalStockAnalysis_InternationalRegions_NoCurrentHoldings'] | getFromAEM"></em><p>
                    </div>
                </div>
                <div class="hypothetical-side">
                    <h3>{{tableData.hypothetical.header}}</h3>
                    <table role="presentation" class="table holdingsTable hypothetical" *ngIf="tableData.hypothetical.accounts.length > 0">
                        <ng-container [ngTemplateOutlet]="holdingsTableHeaders"></ng-container>
                        <tr *ngFor="let acct of tableData.hypothetical.accounts" [ngClass]="{'totalRow': acct.name === 'Subtotal', 'accountName': !acct.value && !acct.percentage}">
                            <td>{{acct.name}}</td>
                            <td *ngIf="acct.value">{{acct.value | currency}}</td>
                            <td *ngIf="acct.value">{{acct.percentage | percentFormatted: null: acct.value}}</td>
                        </tr>
                    </table>
                    <div *ngIf="!tableData.hypothetical.accounts.length > 0">
                        <p><em [innerText]="content['TesterTool_HypotheticalStockAnalysis_InternationalRegions_NoCurrentHoldings'] | getFromAEM"></em><p>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="side-by-side-tables-totals">
            <div class="actual-side">
                <table role="presentation" class="table holdingsTable">
                    <tr class="totalRow">
                        <td [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_Total'] | getFromAEM"></td>
                        <td>{{chartData.stockInternationalRegions.applicableBalance | currency}}</td>
                        <td>{{this.holdingsTotal + '%'}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class="hypothetical-side">
                <table role="presentation" class="table holdingsTable hypothetical">
                    <tr class="totalRow">
                        <td [innerHTML]="content['TesterTool_HypotheticalStockAnalysis_Total'] | getFromAEM"></td>
                        <td>{{hypotheticalData.stockInternationalRegions.applicableBalance | currency}}</td>
                        <td>{{this.holdingsTotalHypothetical + '%'}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-template #holdingsTableHeaders>
        <tr>
            <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_Name'] | getFromAEM"></th>
            <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_Value'] | getFromAEM"></th>
            <th [innerHTML]="content['PWStocks_DetailsCard_InternationalRegions_Headers_Percentage'] | getFromAEM"></th>
        </tr>
    </ng-template>
</div>

