<div class="tester-background modernized">
    <div class="tester-tool-view" #content="pwContent" pwContent *ngIf="hasLoadedChartData">
        <h1 *ngIf="!isEnabledAEMGatekeeper" class="header" id="tester-tool-header" 
            [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerHeader'] + selectedAccountGroup.GroupName" tabindex="0" aria-live="polite" role="alert" [attr.aria-label]="content['PortfolioTester_HypoHoldingsForm_TickerHeader'] + selectedAccountGroup.GroupName">
        </h1>
        <h1 *ngIf="isEnabledAEMGatekeeper" class="header"  id="tester-tool-header-aem"
            [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerHeader'] | getFromAEM: {'selected-group-name': selectedAccountGroup.GroupName}" tabindex="0" aria-live="polite" role="alert"  [attr.aria-label]="content['PortfolioTester_HypoHoldingsForm_TickerHeader'] | getFromAEM: {'selected-group-name': selectedAccountGroup.GroupName}">
        </h1>
        <h3 [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerPurchase'] | getFromAEM"></h3>
        <div aria-live="polite" id="addition-announcement" class="sr-only"></div>
        <div class="formRowAdditions" *ngFor="let addition of additionsFormArray.controls; let i = index"
            id="formRowAdditions-{{i}}">
            <ng-container [formGroup]="addition">
                <div class="ticker-wrapper">
                    <div>
                        <button class="close-addition" (click)="deleteAdditionLine(i)" [style]="'secondary'"
                            aria-label="clickable remove line">
                            <span class="icon icon-plus" id="remove-button"></span>
                        </button>
                    </div>
                    <div class="ticker">
                        <div>
                            <label class="c11n-label c11n-label--large" for="ticker-{{i}}"
                                [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerLabel'] | getFromAEM"></label>
                            <div class="c11n-input c11n-input--medium ">
                                <input type="text" id="ticker-{{i}}" name="input-text" formControlName="tickerControl"
                                    (focus)="validateTicker(i, true)" (blur)="validateTicker(i)" (input)="buttonCheck()"
                                    class="c11n-input__input" aria-describedby="ticker-{{i}}-description"
                                    aria-required="true" #tickerInput>
                                <div class="c11n-input__focus-element"></div>
                            </div>
                            <div class="c11n-error error tickerError" id="ticker-error-{{i}}">
                                <span class="c11n-error__icon">
                                    <svg role="img" class="c11n-error-small" aria-labelledby="title">
                                        <title>error icon</title>
                                        <use xlink:href="#error-small"></use>
                                    </svg>
                                </span>
                                <span id="ticker-{{i}}-description" role="alert" class="c11n-error__content"
                                    aria-label="error"
                                    [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerErrorSymbol'] | getFromAEM"></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown">
                    <label class="dropdown-label__content" for="ticker-dropdown-{{i}}"
                        [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerDropdownLabel'] | getFromAEM"></label>
                    <c11n-select [labelText]="''" [size]="'medium'" [hintText]="''" (stateChange)="buttonCheck();">
                        <select c11nSelectInput id="ticker-dropdown-{{i}}" formControlName="accountControl"
                            aria-describedby="ticker-dropdown-{{i}}-description" (blur)="validateAccountGroup(i)"
                            (onChange)="validateAccountGroup(i)" (focus)="validateAccountGroup(i, true)"
                            class="c11n-select__box__select" aria-required="true" #tickerDropdown>
                            <option value="0" disabled selected>Select one</option>
                            <option *ngFor="let account of accounts" [value]="account.accountId">
                                {{ account.accountName }}
                            </option>
                        </select>
                    </c11n-select>
                    <div id="ticker-account-error-{{i}}" class="c11n-error error tickerError">
                        <span class="c11n-error__icon">
                            <svg role="img" class="c11n-error-small" aria-labelledby="title">
                                <title>error icon</title>
                                <use xlink:href="#error-small"></use>
                            </svg>
                        </span>
                        <span id="ticker-{{i}}-description" role="alert" class="c11n-error__content" aria-label="error"
                            [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerErrorAccount'] | getFromAEM"></span>
                    </div>
                </div>
                <div class="value">
                    <div>
                        <label class="c11n-label c11n-label--large" for="ticker-value-{{i}}"
                            [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerValueLabel'] | getFromAEM"></label>
                        <div class="c11n-input c11n-input--medium ">
                            <input type="number" id="ticker-value-{{i}}" name="input-text"
                                formControlName="valueControl" (blur)="validateNumber(i)" (input)="buttonCheck()"
                                (focus)="validateNumber(i, true)" class="c11n-input__input"
                                aria-describedby="ticker-value-{{i}}-description" aria-required="true" #tickerValue>
                            <div class="c11n-input__focus-element"></div>
                        </div>
                        <div class="c11n-error error tickerError" id="ticker-value-error-{{i}}">
                            <span class="c11n-error__icon">
                                <svg role="img" class="c11n-error-small" aria-labelledby="title">
                                    <title>error icon</title>
                                    <use xlink:href="#error-small"></use>
                                </svg>
                            </span>
                            <span id="ticker-value-{{i}}-description" role="alert" class="c11n-error__content"
                                aria-label="error"
                                [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerErrorValue'] | getFromAEM"></span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="button-wrapper">
            <span>
                <c11n-button [ariaLabelText]="content['PortfolioTester_HypoHoldingsForm_AddALine'] | getFromAEM"
                    [labelText]="content['PortfolioTester_HypoHoldingsForm_AddALine'] | getFromAEM"
                    (clickEvent)="addAdditionLine(); buttonCheck()" variant='secondary'  aria-live="polite"></c11n-button>
            </span>
            <span class="button-analyze">
                <c11n-button id="button-analyze-top"
                    [ariaLabelText]="content['PortfolioTester_HypoHoldingsForm_Analyze'] | getFromAEM"
                    [labelText]="content['PortfolioTester_HypoHoldingsForm_Analyze'] | getFromAEM"
                    (clickEvent)="analyzeHypothetical()" [isInvalid]="!enableAnalyze"
                    [isWaiting]="isAnalyzing">
                </c11n-button>
            </span>
        </div>
        <ng-container *ngIf="hasLoadedChartData">
            <h2 id="holdings-header" class="header" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_HoldingsHeader'] | getFromAEM"></h2>
            <h3 [innerHTML]="content['PortfolioTester_HypoHoldingsForm_HoldingsSubheader'] | getFromAEM"></h3>
            <div style="overflow-x: auto;" *ngFor="let acct of accountsWithHoldings; let i = index">
                <c11n-accordion [labelText]="acct.accountName" (stateChange)="myStateChangeHandler($event)" [id]="'accordionAccountTable_'+i" >
                    <div accordion-body *ngIf="accordionsEnabled.has('accordionAccountTable_'+i)">
                        <table class="accountTable">
                            <tr class="formRowChanges headers">
                                <th scope="col" class="name" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_Name'] | getFromAEM"></th>
                                <th scope="col" class="percent" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_Value'] | getFromAEM"></th>
                                <th scope="col" class="balance"></th>
                                <th scope="col" class="change">
                                    <div>
                                        <div class="c11n-error error tickerError" style="display: flex !important" *ngIf="showColumnError || positionId">
                                            <span class="c11n-error__icon">
                                                <svg role="img" class="c11n-error-small" aria-labelledby="title">
                                                    <title>error icon</title>
                                                    <use xlink:href="#error-small"></use>
                                                </svg>
                                            </span>
                                            <span role="alert" class="c11n-error__content" aria-label="error" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_TickerErrorValue'] | getFromAEM"></span>
                                        </div>
                                        {{content['PortfolioTester_HypoHoldingsForm_ChangeValue'] | getFromAEM}}
                                    </div>
                                </th>
                            </tr>
                            <ng-container *ngFor="let holdingGroup of acct.formGroups.controls; let j = index">
                                <ng-container [formGroup]="holdingGroup">
                                  <tr class="formRowChanges">
                                    <th scope="row" class="name content">{{holdingGroup.get("fundNameControl").value}}</th>
                                    <td class="percent content">{{holdingGroup.get("percentageControl").value | percentFormatted}}</td>
                                    <td class="balance content">{{holdingGroup.get("fundBalanceControl").value | currency}}</td>
                                    <td class="change content">
                                      <input class="holdings input" (focus)="holdingsErrorStyle(i, j, holdingGroup)" (blur)="holdingsErrorStyle(i, j, holdingGroup)" id="holdings-value-change-{{i}}{{j}}" type="number" formControlName="balanceChangeControl" [attr.aria-label]="content['PortfolioTester_HypoHoldingsForm_ChangeValue'] | getFromAEM" aria-describedby="number-error-{{i}}{{j}}" />
                                      <div class="error" id="number-error-{{i}}{{j}}">
                                        <span class="c11n-error__icon">
                                          <svg role="img" class="c11n-error-small">
                                            <use xlink:href="#error-small"></use>
                                          </svg>
                                        </span>
                                        <span role="alert" aria-label="error" [innerHTML]="content['PortfolioTester_HypoHoldingsForm_HoldingsError'] | getFromAEM"></span>
                                      </div>
                                    </td>
                                  </tr>
                                </ng-container>
                              </ng-container>
                        </table>
                    </div>
                </c11n-accordion>
            </div>
            <div class="button-wrapper">
                <div *ngIf="hypotheticalCallError" class="error callError">
                    <span class="alert-icon"></span>
                    <span [innerHTML]="content['PortfolioTester_HypoHoldingsForm_CallError'] | getFromAEM"></span>
                </div>
                <span>
                    <c11n-button [ariaLabelText]="content['PortfolioTester_HypoHoldingsForm_Clear'] | getFromAEM"
                        [labelText]="content['PortfolioTester_HypoHoldingsForm_Clear'] | getFromAEM" (clickEvent)="clearData()"
                        variant='secondary'></c11n-button>
                </span>
                <!-- Aria-live region for screen reader feedback -->
                <div aria-live="polite" aria-atomic="true" class="sr-only">
                    {{ srMessage }}
                </div>
                <span class="button-analyze">
                    <c11n-button id="button-analyze-bottom"
                        [ariaLabelText]="content['PortfolioTester_HypoHoldingsForm_Analyze'] | getFromAEM"
                        [labelText]="content['PortfolioTester_HypoHoldingsForm_Analyze'] | getFromAEM"
                        (clickEvent)="analyzeHypothetical()" [isInvalid]="!enableAnalyze"></c11n-button>
                </span>               
            </div>
        </ng-container>
    </div>
</div>