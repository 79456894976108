import { Component, Input, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { PortfolioAlert } from '../../models/chart-data-response-models/PortfolioAlert';
import { AlertsHelperService } from '../../services/alerts-helper/alerts-helper.service';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Component({
  selector: 'PW1-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.scss'],
})
export class AlertNotificationsComponent implements OnInit {
  @Input() pageName;
  chartData: AllChartsResponseData;

  alertsForPage: Array<PortfolioAlert> = new Array<PortfolioAlert>();

  constructor(
    public chartDataService: PortfolioDataService,
    public logger: LoggerService,
    public alertsService: AlertsHelperService,
  ) {}

  ngOnInit() {
    this.chartDataService.getChartDataPayload().subscribe((data) => {
      this.chartData = data;
      this.setAlerts();
    });
  }
  setAlerts() {
    const allAlerts = this.alertsService.getAlertsList(this.chartData);
    this.alertsForPage = allAlerts.filter((alert) => alert.url === this.pageName);
  }

  removeAlert(alert) {
    this.alertsService.removeAlert(alert, this.chartData, this.logger, this.chartDataService);
  }
}
