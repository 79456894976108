<div *ngIf='chartData' class="tab-table modernizedTabs">
    <c11n-tabs
        activeItem="market-capitalization"
        [hasAnimation]="true"
        (activeItemChange)="updateUrl($event)"
    >
        <c11n-tab-panel labelText="Market capitalization" role="link" id="market-capitalization">
            <market-cap-table [chartData]='chartData'></market-cap-table>
        </c11n-tab-panel>
        <c11n-tab-panel labelText="Investment style" role="link" id="investment-style">
            <investment-style-table [chartData]='chartData'></investment-style-table>
        </c11n-tab-panel>
        <c11n-tab-panel labelText="Industry sectors" role="link" id="industry-sectors">
            <industry-sectors-table [chartData]='chartData'></industry-sectors-table>
        </c11n-tab-panel>
        <c11n-tab-panel labelText="International regions" role="link" id="international-regions">
            <international-regions-table [chartData]='chartData'></international-regions-table>
        </c11n-tab-panel>
    </c11n-tabs>
</div>
