<div class="alerts-container">
    <div id="portfolioSummary" class="main-card" [ngClass]="{'alertsDisplayed': openCard}" #content="pwContent"
        pwContent>
        <div class="bottom-row" (click)="viewAlerts()" tabindex="0">
            <div class="alerts-section">
                <div id="alert-list" class="alert-block">
                    <span class="alert-caution" aria-label="icon alert-caution" tabindex="0"></span>
                    <span class="alert-title"
                        [innerHTML]="content['PWOverview_PortfolioAlerts'] | getFromAEM : {'portfolio-alert-count': alertList.length}"
                        tabindex="0"></span>
                    <span class="alert-list-length" *ngIf="!isEnableAEMGatekeeper">({{alertList.length}})</span>
                </div>

                <div class="showMoreAlerts">
                    <button class="navigateLink toggleAlerts" *ngIf="alertList.length > 0" tabindex="0"
                        [attr.aria-expanded]="openCard" aria-live="polite" aria-label="Portfolio Alerts button">
                        <span class="icon icon-down-arrow-red" *ngIf="!openCard"></span>
                        <span class="icon icon-up-arrow-red" *ngIf="openCard"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="alert-disclaimer" *ngIf="alertList.length > 0"
            [innerHtml]="content['PWOverview_PortfolioAlerts_Disclaimer'] | getFromAEM">
        </div>
    </div>
    <div class="alert-card" *ngIf="openCard && alertList.length > 0">
        <ul class="accessibility">
            <li *ngFor="let alert of alertList">
                <div class="alert-row box" (click)="navigateToAlertPage(alert.url)">
                    <div class="alert-row alert-details-container">
                        <div class="alert-details">
                            <span class="status">{{alert.status}}:</span>
                            <ng-container *ngIf="alert.shortMessage">
                                <span class="message" [innerHTML]="content[alert.shortMessage] | getFromAEM"></span>
                            </ng-container>
                        </div>
                    </div>
                    <div class="alert-icons">
                        <button class="close" aria-label="Remove alert button" (click)="removeAlert(alert)">
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="alerts-container-print" *ngIf="alertList.length > 0">
    <div id="portfolioSummaryPrint" class="main-card" [ngClass]="{'alertsDisplayed': openCard}" #content="pwContent"
        pwContent>
        <div class="bottom-row">
            <div class="alerts-section">
                <div id="alert-list-print" class="alert-block">
                    <span class="alert-caution"></span>
                    <span class="alert-title"
                        [innerHTML]="content['PWOverview_PortfolioAlerts'] | getFromAEM : {'portfolio-alert-count': alertList.length}"
                        tabindex="0"></span>
                    <span class="alert-list-length" *ngIf="!isEnableAEMGatekeeper">({{alertList.length}})</span>
                </div>
            </div>
        </div>
        <div class="alert-disclaimer" *ngIf="alertList.length > 0"
            [innerHtml]="content['PWOverview_PortfolioAlerts_Disclaimer'] | getFromAEM">
        </div>
    </div>
    <div class="alert-card">
        <div class="alert-row box" *ngFor="let alert of alertList">
            <div class="alert-row alert-details-container">
                <div class="alert-details">
                    <span class="status">{{alert.status}}:</span>
                    <ng-container *ngIf="alert.shortMessage">
                        <span class="message" [innerHTML]="content[alert.shortMessage] | getFromAEM"></span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>