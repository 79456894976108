import { Component, OnInit } from '@angular/core';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'stock-analysis-tab-table',
  templateUrl: './stock-analysis-tab-table.component.html',
  styleUrls: ['./stock-analysis-tab-table.component.scss'],
})
export class StockAnalysisTabTableComponent implements OnInit {
  selectedTabIndex;
  navigation;
  chartData;
  

  constructor(
    private portfolioDataService: PortfolioDataService,
    private router: Router,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {
    this.navigation = this.router.getCurrentNavigation();
  }

  ngOnInit() {
    this.portfolioDataService.getChartDataPayload().subscribe((data) => {
      if (data) {
        this.chartData = data;
      }
    });
    this.selectedTabIndex = this.route.snapshot.data['tabId'];

    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
  }

  setFocusTotabTrigger(): void {
    const tabTriggerElement = document.querySelector('.mat-tab-label-content');
    if (tabTriggerElement) {
      (tabTriggerElement as HTMLElement).focus();
    }
}

  updateUrl(tabId) {
    const url: string = '/bond-analysis/' + tabId;
    window.history.replaceState({}, '', url)
    this.adobeAnalyticsService.clickedOnTab(url);
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });
  }
}
