<div #content="pwContent" pwContent [ngClass]="{'fullWidth': isFullWidth}" class="stockDashboardWrapper">
    <div class="accessibility">
        {{ content['PWStocks_DetailsCard_PercentStocks'] | getFromAEM: {'percent-value': stockPercentage} }}
        {{ '' +
            content?.['PWStocks_DetailsCard_STOCKS'] | getFromAEM
        }}
        <button class="icon icon-question-mark-black" id="helpStockDashboard" (click)="openStockAnalysisDrawer()"
            aria-label="Help Button"></button>
    </div>
    <stacked-bar-chart [barChartData]=actualStockChartData [isFullWidth]="isFullWidth"></stacked-bar-chart>
    <div class="chartLegend">
        <span *ngFor="let slice of actualStockChartData.chartSlices">
            <svg height="12" width="12">
                <circle cx="6" cy="6" r="6" attr.fill="{{slice.color}}" />
            </svg>
            <span>{{slice.label}}</span>
        </span>
        <a (click)="trackDomesticVsInternationalLink()"
            [routerLink]="'/stock-analysis/domestic-vs-international'">
            {{content['PWStocks_DetailsCard_DomesticVsIntlHoldingsDetails'] | getFromAEM}}
            <i class="icon icon-right-arrow-blue-modernized"></i>
        </a>
    </div>
</div>