<div #content="pwContent" pwContent class="tableComponentWrapper">
  <PW1-alert-notifications [pageName]="'/stock-analysis/market-capitalization'"></PW1-alert-notifications>
  <div class="tableHeaderWrapper">
    <div class="titleAndExport">
      <div class='tableTitle'>
        <h2 class="accessibility">
          <span [innerHTML]="content['PWStocks_DetailsCard_MarketCap'] | getFromAEM"></span>
          <button id="helpMarketCapitalization" class="icon icon-question-mark-black" (click)="openMarketCapDrawer()"
            aria-label="Help Button"></button>
          <PW1-export-to-csv *ngIf="viewByHoldings" [parsableArray]="generateTableDataByHoldingsModernized()"
            [fileName]="'StockMarketCapData'" [modernized]="true">
          </PW1-export-to-csv>
        </h2>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="domesticDisclaimer" [innerHTML]="content['PWStocks_DetailsCard_MarketCap_Disclaimer'] | getFromAEM"></div>
    <div class="spacer"></div>
    <PW1-table-view-controls (tableViewChanged)="changeTableView($event)"></PW1-table-view-controls>
  </div>
  <ng-container *ngIf="loaded && !viewByHoldings">
    <table class="c11n-table c11n-table--medium">
      <thead>
        <tr class="c11n-table__tr headers" scope="row">
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">
            <strong>{{content['PWStocks_DetailsCard_MarketCap_Headers_Size'] | getFromAEM}}</strong></th>
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">
            <strong>{{content['PWStocks_DetailsCard_MarketCap_Headers_USStockPortfolio'] | getFromAEM}}</strong></th>
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">
            <strong>{{content['PWStocks_DetailsCard_MarketCap_Headers_USStockMarket'] | getFromAEM}}</strong></th>
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">
            <strong>{{content['PWStocks_DetailsCard_MarketCap_Headers_Difference'] | getFromAEM}}</strong></th>
        </tr>
      </thead>
      <tbody>
        <tr class="c11n-table__tr" *ngFor="let tableRow of tableData"
          [ngClass]="{'totalRow': tableRow.size === 'Total'}" scope="row">
          <td scope="col" class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left">{{tableRow.size}}</td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.yourStock}}</td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.market}}</td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.difference}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="loaded && viewByHoldings">
    <PW1-holdings-table [tableData]="holdingsTableDataModernized"></PW1-holdings-table>
  </ng-container>
</div>