import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { GatekeeperService } from 'src/app/services/gate-keeper/gatekeeper.service';

@Component({
  selector: 'PW1-target-mix-about',
  templateUrl: './target-mix-about.component.html',
  styleUrls: [
    './target-mix-about.component.scss', 
  ],
})
export class TargetMixAboutComponent {
  isEnabledAEMGatekeeper: boolean;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private readonly gatekeeperService: GatekeeperService
  ) {
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });

    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isAEMEnabled) => {
      this.isEnabledAEMGatekeeper = isAEMEnabled;
    });
  }

  navigateBackToPortfolioWatch() {
    this.adobeAnalyticsService.clickedBackToOverview();
    this.router.navigate(['/']);
  }
}
