<div #content="pwContent" pwContent [ngClass]="{'fullWidth': isFullWidth}" class="bondDashboardWrapper">
    <h3>
        {{
            content?.['PWBond_DetailsCard_PercentBonds_I'] | getFromAEM: {'percent-value': bondPercentage}
        }} {{ '' +
            content?.['PWBond_DetailsCard_PercentBonds_II'] | getFromAEM
        }}
        <button class="icon icon-question-mark-black" (click)="openBondAnalysisDrawer()"
            aria-label="Help Button"></button>
    </h3>
    <stacked-bar-chart [barChartData]=actualBondChartData [isFullWidth]="isFullWidth"></stacked-bar-chart>
    <div class="chartLegend">
        <span *ngFor="let slice of actualBondChartData.chartSlices">
            <svg height="12" width="12">
                <circle cx="6" cy="6" r="6" attr.fill="{{slice.color}}" />
            </svg>
            <span>{{slice.label}}</span>
        </span>
        <a 
            (click)="trackDomesticVsInternationalLink()"
            [routerLink]="'/bond-analysis/domestic-vs-international'"
            >
            {{ content['PWBond_DetailsCard_DomesticVsIntlHoldingsDetails'] | getFromAEM }}
            <i class="icon icon-right-arrow-blue-modernized"></i>
        </a>
    </div>
</div>