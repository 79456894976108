<c11n-card id="stockAnalysis" #content="pwContent" pwContent>
    <div cardHeading class="titleWrapper">
        <div class="titleWrapper__row">
            <h3 *ngIf="!isHypotheticalData" class="accessibility" [innerHTML]="content['PWOverview_StockAnalysis'] | getFromAEM">
            </h3>
            <span *ngIf="isHypotheticalData" class="center-vertically"
                [innerHTML]="content['TesterTool_Overview_HypotheticalStockCard_Title'] | getFromAEM"></span>
            <p class="titleWrapper__title">
                <button class="icon icon-question-mark-black " (click)="selectSection('Stock analysis')"
                    aria-label="Stock analysis help button"></button>
            </p>
            <button cardAction #cardAction *ngIf="hasStocks" class="navigateLink seeDetails"
                aria-label='Stock Analysis see details' (click)="goToStockAnalysisPage()">
                <span [innerHTML]="content['PWOverview_StockAnalysis_SeeDetails'] | getFromAEM"></span>
                <i class="icon icon-right-arrow-blue-modernized"></i>
            </button>
        </div>
    </div>
    <div cardBody>
        <p *ngIf="!hasStocks; else stocks;" [innerHTML]="content[contentNumber] | getFromAEM"></p>
        <ng-template #stocks>
            <div [ngClass]="{'isHypothetical': isHypotheticalData}" class="modernizedContainer">
                <div class="cardChart">
                    <PW1-donut-chart-component [id]="chartId" [dataSet]="stockAnalysisChartData" [height]="188"
                        [width]="188"></PW1-donut-chart-component>
                </div>
                <div class="cardLegend">
                    <PW1-chart-legend [legendArray]="legendArrayModern" [legendHeader]="legendHeader">
                    </PW1-chart-legend>
                </div>
            </div>
        </ng-template>
    </div>
</c11n-card>