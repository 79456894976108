<div #content="pwContent" pwContent class="tableComponentWrapper">
  <PW1-alert-notifications [pageName]="'/costs/tax-efficiency'"></PW1-alert-notifications>
  <div class="tableHeaderWrapper">
    <div class="tableTitle">
      <h2 class="accessibility">
        <span [innerHTML]="content['PWCosts_DetailsCard_TaxEfficiency_TaxEfficiency'] | getFromAEM"></span>
        <button class="icon icon-question-mark-black" (click)="openTaxEfficiencyDrawer()" aria-label="Help Button"></button>
        <PW1-export-to-csv
          [parsableArray]="csvTableData"
          [fileName]="'TaxEfficiencyData'"
          [modernized]="true"
        ></PW1-export-to-csv>
    </h2>
    </div>
  </div>

  <ng-container *ngIf="loaded">
    <div *ngFor="let category of taxEffTable">
      <h3><div class="categorizationHeader">{{ category.holdingsCategory }}</div></h3>
      <table role="presentation" class="c11n-table c11n-table--medium table holdingsTable">
        <tbody>
          <tr
            class="c11n-table__tr"
            *ngFor="let assetItem of category.holdings"
            [ngClass]="{ totalRowModernized: assetItem.assets === 'Total' }"
          >
            <td scope="row" class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left">
              {{ assetItem.assets }}
            </td>
            <td class="c11n-table__td c11n-table__td--align-right">
              {{ assetItem.assetsPercentage | percentFormatted : 2 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>
