<div #content="pwContent" pwContent>
  <div class="header">
    <div class="container">
      <button class="back" aria-label="Back to Portfolio Watch" (click)="navigateBackToPortfolioWatch()">
        <i class="icon icon-left-arrow-blue-modernized"></i><span
          [innerHTML]="content['PWQuestionnaire_BackToPortfolioWatch'] | getFromAEM"></span>
      </button>
      <div class="page-title">
        {{ content['PWTargetMixAbout_Title'] | getFromAEM }}
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="container">
      <c11n-tabs activeItem="assumptions" [hasAnimation]="true" class="tab-content-wrapper">
        <c11n-tab-panel labelText="Assumptions" id="assumptions">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Assumptions_Content'] | getFromAEM"
              *ngIf="!isEnabledAEMGatekeeper">
            </div>
            <div class="main-content" *ngIf="isEnabledAEMGatekeeper">
              <h1 [innerHtml]="content['PWTargetMixAbout_Assumptions_Content_Title'] | getFromAEM"></h1>
              <h2 [innerHtml]="content['PWTargetMixAbout_Assumptions_Content_SubTitle'] | getFromAEM"></h2>
              <div [innerHtml]="content['PWTargetMixAbout_Assumptions_Content_Subtitle_Paragraph'] | getFromAEM"></div>
              <h2 [innerHtml]="content['PWTargetMixAbout_Assumptions_Content_2_Subtitle'] | getFromAEM"></h2>
              <div [innerHtml]="content['PWTargetMixAbout_Assumptions_Content_2_Subtitle_Paragraph'] | getFromAEM">
              </div>
              <table>
                <tr>
                  <th>{{ content['PWTargetMixAbout_Assumptions_Content_Table_Header_1'] | getFromAEM }}</th>
                  <th>{{ content['PWTargetMixAbout_Assumptions_Content_Table_Header_2'] | getFromAEM }}</th>
                </tr>
                <tr>
                  <td>{{ content['PWTargetMixAbout_Assumptions_Content_Table_L1H1'] | getFromAEM }}</td>
                  <td>{{ content['PWTargetMixAbout_Assumptions_Content_Table_L1H2'] | getFromAEM }}</td>
                </tr>
                <tr>
                  <td>{{ content['PWTargetMixAbout_Assumptions_Content_Table_L2H1'] | getFromAEM }}</td>
                  <td>{{ content['PWTargetMixAbout_Assumptions_Content_Table_L2H2'] | getFromAEM }}</td>
                </tr>
                <tr>
                  <td>{{ content['PWTargetMixAbout_Assumptions_Content_Table_L3H1'] | getFromAEM }}</td>
                  <td>{{ content['PWTargetMixAbout_Assumptions_Content_Table_L3H2'] | getFromAEM }}</td>
                </tr>
              </table>
              <br>
              <div class="disclaimer" [innerHtml]="content['PWTargetMixAbout_Assumptions_Disclaimer_1'] | getFromAEM">
              </div>
              <div class="disclaimer" [innerHtml]="content['PWTargetMixAbout_Assumptions_Disclaimer_2'] | getFromAEM">
              </div>
              <div class="disclaimer" [innerHtml]="content['PWTargetMixAbout_Assumptions_Disclaimer_3'] | getFromAEM">
              </div>
            </div>
          </div>
        </c11n-tab-panel>
        <c11n-tab-panel labelText="Limitations" id="limitations">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Limitations_Content'] | getFromAEM"
              *ngIf="!isEnabledAEMGatekeeper">
            </div>
            <div class="main-content" *ngIf="isEnabledAEMGatekeeper">
              <h1 [innerHtml]="content['PWTargetMixAbout_Limitations_Content_Title'] | getFromAEM"></h1>
              <div [innerHtml]="content['PWTargetMixAbout_Limitations_Content'] | getFromAEM">
              </div>
            </div>
          </div>
        </c11n-tab-panel>
        <c11n-tab-panel labelText="Considerations" id="considerations">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Considerations_Content'] | getFromAEM"
              *ngIf="!isEnabledAEMGatekeeper">
            </div>
            <div class="main-content" *ngIf="isEnabledAEMGatekeeper">
              <h1 [innerHtml]="content['PWTargetMixAbout_Considerations_Content_Title'] | getFromAEM"></h1>
              <div [innerHtml]="content['PWTargetMixAbout_Considerations_Content'] | getFromAEM">
              </div>
            </div>
          </div>
        </c11n-tab-panel>
        <c11n-tab-panel labelText="Help" id="help">
          <div class="tab-content">
            <div class="main-content" [innerHTML]="content['PWTargetMixAbout_Help_Content'] | getFromAEM"
              *ngIf="!isEnabledAEMGatekeeper">
            </div>
            <div class="main-content" *ngIf="isEnabledAEMGatekeeper">
              <h1 [innerHtml]="content['PWTargetMixAbout_Help_Content_Title'] | getFromAEM"></h1>
              <h2 [innerHtml]="content['PWTargetMixAbout_Help_Content_SubTitle_1'] | getFromAEM"></h2>
              <div [innerHtml]="content['PWTargetMixAbout_Help_Content_Subtitle_Paragraph_1'] | getFromAEM"></div>
              <h2 [innerHtml]="content['PWTargetMixAbout_Help_Content_SubTitle_2'] | getFromAEM"></h2>
              <div [innerHtml]="content['PWTargetMixAbout_Help_Content_Subtitle_Paragraph_2'] | getFromAEM"></div>
              <h2 [innerHtml]="content['PWTargetMixAbout_Help_Content_SubTitle_3'] | getFromAEM"></h2>
              <div [innerHtml]="content['PWTargetMixAbout_Help_Content_Subtitle_Paragraph_3'] | getFromAEM"></div>
              <h2 [innerHtml]="content['PWTargetMixAbout_Help_Content_SubTitle_4'] | getFromAEM"></h2>
              <div [innerHtml]="content['PWTargetMixAbout_Help_Content_Subtitle_Paragraph_4'] | getFromAEM"></div>
              <h2 [innerHtml]="content['PWTargetMixAbout_Help_Content_SubTitle_5'] | getFromAEM"></h2>
              <div [innerHtml]="content['PWTargetMixAbout_Help_Content_Subtitle_Paragraph_5'] | getFromAEM"></div>
            </div>
          </div>
        </c11n-tab-panel>
      </c11n-tabs>
      <div class="side-content">
        <advice-banner></advice-banner>
      </div>
    </div>
  </div>
</div>