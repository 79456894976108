<div #content="pwContent" pwContent>
    <c11n-accordion [labelText]="content['PWAsset_DetailsCard_Accordion_HoldingsByAssetType'] | getFromAEM" [isExpanded]="false">
  <div accordion-body>
    <div class="c11n-accordion-story-content">
        <ng-container *ngFor="let tableData of allTablesData; let last = last">
            <div class="side-by-side-tables-categories" [ngClass]="{ last: last }"
                *ngIf="tableData.actual.accounts.length > 0 || tableData.hypothetical.accounts.length > 0">
                <div class="actual-side">
                    <h3>{{tableData.actual.header}}</h3>
                    <table role="presentation" class="table holdingsTable" *ngIf="tableData.actual.accounts.length > 0">
                        <ng-container [ngTemplateOutlet]="holdingsTableHeaders"></ng-container>
                        <tr *ngFor="let acct of tableData.actual.accounts"
                            [ngClass]="{'totalRow': acct.name === 'Subtotal', 'accountName': !acct.value && !acct.percentage}">
                            <td>{{acct.name}}</td>
                            <td *ngIf="acct.value">{{acct.value | currency}}</td>
                            <td *ngIf="acct.value">{{acct.percentage | percentFormatted: null: acct.value}}</td>
                        </tr>
                    </table>
                    <div *ngIf="!tableData.actual.accounts.length > 0">
                        <p><em>No current holdings.</em>
                        <p>
                    </div>
                </div>
                <div class="hypothetical-side">
                    <h3>{{tableData.hypothetical.header}}</h3>
                    <table role="presentation" class="table holdingsTable hypothetical"
                        *ngIf="tableData.hypothetical.accounts.length > 0">
                        <ng-container [ngTemplateOutlet]="holdingsTableHeaders"></ng-container>
                        <tr *ngFor="let acct of tableData.hypothetical.accounts"
                            [ngClass]="{'totalRow': acct.name === 'Subtotal', 'accountName': !acct.value && !acct.percentage}">
                            <td>{{acct.name}}</td>
                            <td *ngIf="acct.value">{{acct.value | currency}}</td>
                            <td *ngIf="acct.value">{{acct.percentage | percentFormatted: null: acct.value}}</td>
                        </tr>
                    </table>
                    <div *ngIf="!tableData.hypothetical.accounts.length > 0">
                        <p><em>No current holdings.</em>
                        <p>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="side-by-side-tables-totals">
            <div class="actual-side">
                <table role="presentation" class="table holdingsTable">
                    <tr class="totalRow">
                        <td [innerHTML]="content['TesterTool_HypotheticalAssetPage_Table_Total'] | getFromAEM"></td>
                        <td>{{chartData.assetTypes.applicableBalance | currency}}</td>
                        <td>{{this.holdingsTotal + '%'}}</td>
                    </tr>
                </table>
            </div>
            <div class="hypothetical-side">
                <table role="presentation" class="table holdingsTable hypothetical">
                    <tr class="totalRow">
                        <td [innerHTML]="content['TesterTool_HypotheticalAssetPage_Table_Total'] | getFromAEM"></td>
                        <td>{{hypotheticalData.assetTypes.applicableBalance | currency}}</td>
                        <td>{{this.holdingsTotalHypothetical + '%'}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <ng-template #holdingsTableHeaders>
            <tr>
                <th [innerHTML]="content['TesterTool_HypotheticalAssetPage_Table_Name'] | getFromAEM"></th>
                <th [innerHTML]="content['TesterTool_HypotheticalAssetPage_Table_Value'] | getFromAEM"></th>
                <th [innerHTML]="content['TesterTool_HypotheticalAssetPage_Table_Percent'] | getFromAEM"></th>
            </tr>
        </ng-template>
    </div>
  </div>
</c11n-accordion>
</div>
