<div #content="pwContent" pwContent class="modernHoldingTables">
    <ng-container *ngFor="let categorization of tableData.categorizations">
        <ng-container *ngIf="categorization.accounts.length > 0">
            <div *ngIf="categorization.header === 'U.S. bonds & bond funds'"
                class="holdings-table-disclaimer mb-4 mt-4">
                <c11n-banner [iconName]="'announcement'" [size]="'small'" [closeAriaLabel]="'close content'"
                    [variant]="'basic'">
                    <div class="c11n-text-md--crop" *ngIf="!isEnabledAEM">
                        {{content['PWAssetMix_BondMessage_1'] | getFromAEM}}
                        <a c11n-link tabindex="0" (click)="navigateToFAQPage()" [variant]="'primary-reinforced'"
                            class="cursor-pointer" [fontWeight]="'normal'"
                            (keyup.enter)="navigateToFAQPage()">{{content['PWAssetMix_BondMessage_LinkText'] |
                            getFromAEM}}
                        </a>
                        {{content['PWAssetMix_BondMessage_2'] | getFromAEM}}
                    </div>
                    <div class="c11n-text-md--crop" *ngIf="isEnabledAEM">
                        {{ (content['PWAssetMix_BondMessage'] | getFromAEM).substring(0, 194) }}
                        <a c11n-link tabindex="0" (click)="navigateToFAQPage()" [variant]="'primary-reinforced'"
                            class="cursor-pointer" [fontWeight]="'normal'"
                            (keyup.enter)="navigateToFAQPage()">
                            {{ content['PWAssetMix_BondMessage_LinkText'] | getFromAEM }}
                        </a>
                        {{ (content['PWAssetMix_BondMessage'] | getFromAEM).substring(211) }}
                    </div>
                </c11n-banner>
            </div>
            <div *ngIf="categorization.header === 'Other asset types'" class="holdings-table-disclaimer mb-4 mt-4">
                <c11n-banner [iconName]="'announcement'" [size]="'small'"
                    [closeAriaLabel]="content['PWAssetMix_OtherMessageClose'] | getFromAEM" [variant]="'basic'">
                    <div class="c11n-text-md--crop">{{ content['PWAssetMix_OtherAssetMessage'] | getFromAEM }}</div>
                </c11n-banner>
            </div>
            <h3 class="categorizationHeader">{{categorization.header}}</h3>
            <ng-container *ngFor="let acct of categorization.accounts">
                <h4 class="accountHeader">{{acct.accountName}}</h4>
                <div class="acessibility">
                    <table class="c11n-table c11n-table--medium">
                        <thead>
                            <tr class="c11n-table__tr">
                                <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left name">
                                    <strong>{{content['PWDomesticVsInternational_Table_Name'] | getFromAEM}}</strong>
                                </th>
                                <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left ticker">
                                    <strong>Symbol</strong>
                                </th>
                                <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right value">
                                    <strong>{{content['PWDomesticVsInternational_Table_Value'] | getFromAEM}}</strong>
                                </th>
                                <th scope="col"
                                    class="c11n-table__thead-th c11n-table__thead-th--align-right percentage">
                                    <strong>{{content['PWDomesticVsInternational_Table_PercentOfPortfolio'] |
                                        getFromAEM}}</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="c11n-table__tr" *ngFor="let holding of acct.holdings">
                                <th scope="row"
                                    class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left name">
                                    {{holding.name}}</th>
                                <td class="c11n-table__td c11n-table__td--align-right ticker">{{holding.ticker}}</td>
                                <td class="c11n-table__td c11n-table__td--align-right value">{{holding.value |
                                    currency}}</td>
                                <td class="c11n-table__td c11n-table__td--align-right percentage">{{holding.percentage |
                                    percentFormatted: null: holding.value}}</td>
                            </tr>
                            <tr class="c11n-table__tr">
                                <td class="c11n-table__td c11n-table__td--align-left subtotalLabel">
                                    <strong>Subtotal</strong>
                                </td>
                                <td class="c11n-table__td c11n-table__td--align-right"></td>
                                <td class="c11n-table__td c11n-table__td--align-right value"><strong>{{acct.subtotal |
                                        currency}}</strong></td>
                                <td class="c11n-table__td c11n-table__td--align-right percentage">
                                    <strong>{{acct.percentageOfPortfolio | percentFormatted: null:
                                        acct.subtotal}}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>