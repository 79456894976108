<c11n-card>
  <div cardHeading>
    <button
      cardAction
      #cardAction
      class="navigateLink"
      aria-label="Back to overview"
      (click)="goBackToOverview()"
    >
      <i class="icon icon-left-arrow-blue"></i>
      <span class="center-vertically" [innerHTML]="backButtonLabel"></span>
    </button>
  </div>
  <div cardHeading class="titleWrapper">
    <h2 class="pageTitle" [innerHTML]="title"></h2>
    <button class="icon icon-question-mark-black" (click)="selectSection()"></button>
  </div>
  <div cardBody>
    <ng-content></ng-content>
  </div>
</c11n-card>
