<c11n-card #content="pwContent" pwContent>
  <div cardHeading class="titleWrapper">
    <div class="titleWrapper__row">
      <h3 *ngIf="!isHypotheticalData" class="accessibility" [innerHTML]="content['PWOverview_AssetAllocation'] | getFromAEM"></h3>
      <span
        *ngIf="isHypotheticalData"
        class="center-vertically"
        [innerHTML]="content['TesterTool_Overview_HypotheticalAssetCard_Title'] | getFromAEM"
      ></span>
      <p class="titleWrapper__title">
        <span *ngIf="!isMFE">
          <button
            class="icon icon-question-mark-black"
            (click)="selectSection('Asset mix')"
            aria-label="Help AssetMix"
          ></button>
        </span>
      </p>
      <button
        cardAction
        #cardAction
        class="navigateLink seeDetails"
        [ngClass]="{ 'hide-see-details': (isChartDataFailed | async) }"
        aria-label="Asset Allocation see details"
        (click)="goToAssetAllocationPage()"
      >
        <span [innerHTML]="content['PWOverview_AssetAllocation_SeeDetails'] | getFromAEM"></span
        ><i class="icon icon-right-arrow-blue-modernized"></i>
      </button>
    </div>
  </div>
  <div cardBody>
    <ng-container *ngIf="isChartDataFailed | async; else assetMixDashboardContent">
      <div class="asset-mix-error-message">
        <c11n-banner
          [size]="'medium'"
          [headingText]="content['AssetMixCard_ErrorState_Title'] | getFromAEM"
          [hasCloseControl]="false"
          [hasDetails]="false"
          [closeAriaLabel]="''"
          [variant]="'error'"
        >
          {{ content['AssetMixCard_ErrorState_Content'] | getFromAEM }}
        </c11n-banner>
      </div>
    </ng-container>
    <ng-template #assetMixDashboardContent>
      <asset-mix-dashboard
        [isFullWidth]="false"
        [isHypotheticalData]="isHypotheticalData"
        [actualAssetAllocationChartId]="chartIdForCurrent"
        [targetAssetAllocationChartId]="chartIdForTarget"
        [isMFE]="false"
      ></asset-mix-dashboard>
    </ng-template>
  </div>
</c11n-card>
