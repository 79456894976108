import { Component, Input, OnInit } from '@angular/core';
import { HoldingsTableData } from '../../models/holdings-table';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { GatekeeperService } from 'src/app/services/gate-keeper/gatekeeper.service';

@Component({
  selector: 'PW1-holdings-table',
  templateUrl: './holdings-table.component.html',
  styleUrls: ['./holdings-table.component.scss'],
})
export class HoldingsTableComponent implements OnInit {
  @Input() tableData: HoldingsTableData;

  isEnabledAEM: boolean;

  constructor(
    public router: Router,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  ngOnInit(): void {
    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isEnabledAEM) => {
      this.isEnabledAEM = isEnabledAEM;
    })
  }

  navigateToFAQPage() {
    this.drawerTrackerService.openDrawer(1);
    this.adobeAnalyticsService.clickedOnFaq();
  }
}
