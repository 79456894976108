import { Component, Input } from '@angular/core';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from '../../../services/adobe-analytics/adobe-analytics.service';
import { PortfolioCardsService } from '../../../services/portfolio-cards/portfolio-cards.service';

@Component({
  selector: 'PW1-hypothetical-card',
  templateUrl: './hypothetical-card.component.html',
  styleUrls: ['./hypothetical-card.component.scss'],
})
export class HypotheticalCardComponent {
  @Input() backButtonLabel: string;
  @Input() title: string;
  @Input() sectionLabel: string;

  constructor(
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private cardsService: PortfolioCardsService,
  ) {}


  goBackToOverview() {
    this.adobeAnalyticsService.clickedBackToOverview(true);
    this.cardsService.navigateToPage('hypothetical-overview');
  }

  selectSection() {
    this.drawerTrackerService.setSelectSection(this.sectionLabel);
  }
}
