import { Component, Input, OnInit } from '@angular/core';
import { GatekeeperService } from 'src/app/services/gate-keeper/gatekeeper.service';

@Component({
  selector: 'PW1-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
  @Input() contentTag: string;
  @Input() headerTag: string;
  @Input() linkTag: string;
  @Input() hasCloseControl = false;

  isEnabledAEMGatekeeper: boolean;

  constructor(private readonly gatekeeperService: GatekeeperService) {}

  ngOnInit() {
    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isEnabledAEMGatekeeper) => {
      this.isEnabledAEMGatekeeper = isEnabledAEMGatekeeper;
    });
  }

  GetLinkUrl(content: string) {
    if (content) {
      return content.split(' : ')[1];
    }
    return null;
  }

  GetLinkText(content: string) {
    if (content) {
      return content.split(' : ')[0];
    }
    return null;
  }
}
