import { AdobeAnalyticsService } from './services/adobe-analytics/adobe-analytics.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { PortfolioDataService } from './services/portfolio-data-service/portfolio-data.service';
import { Observable, filter, Subject, takeUntil } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { WindowRef } from './services/window-ref/window-ref.service';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { LoggerCode } from '../models/logger';
import { LoggerService } from './services/logger/logger.service';
import { targetAssetMixRoutes } from './models/chart-models/target-allocation';
import { DrawerComponent, DrawerTrackerService } from '@vanguard/pfx-components';
import { environment } from 'src/environments/environment';
import { DrawerPageSortMapper } from 'src/config/config.inline-drawer';
import { AemDrawerData } from '@vanguard/pfx-components/lib/components/drawer/drawer-data.interface';
import { default as helpContent } from '../assets/content.help.json';
import { ResizeObserverService } from '@vg-constellation/angular-13/core';
import { DrawerComponent as CDrawerComponent } from '@vg-constellation/angular-13/drawer';
import { CustomUserModernizationService } from './services/custom-user-modernization/custom-user-modernization.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('focusPanelDrawerContainer') fpDrawerElRef!: ElementRef;
  @ViewChild('focusPanelDrawer') focusPanelDrawer: CDrawerComponent;
  FPConfig: any;
  public isLoading: Observable<boolean>;
  public isFailure: Observable<boolean>;
  public showPWHeader: Observable<boolean>;
  isTargetAssetMixPage: boolean = false;
  @ViewChild('pageHelpDrawer') pageHelpDrawer: DrawerComponent;
  drawerHelpContentUrl: string;
  pageHelpDrawerPosition: string = 'end';
  shouldFocusPanelOpen: boolean = false;
  contingencyHelpData: AemDrawerData = helpContent;
  fpDrawerThreshold: number = 992;
  isFpDrawerModal: boolean = false;
  fpDrawerWidth: string = '392px';
  focusPanelMFELoaded: boolean = false;
  private readonly _destroy: Subject<void> = new Subject<void>();
  isFocusPanelOpen: boolean = false;
  currentUrl: string;
  fpDawerSticky: boolean = false;
  isPageHelpOpen: boolean = false;
  customDisplayName: string = '';

  // eslint-disable-next-line max-params
  constructor(
    private titleService: Title,
    private portfolioDataService: PortfolioDataService,
    public router: Router,
    private windowRef: WindowRef,
    private meta: Meta,
    private loggerService: LoggerService,
    private pageHelpDrawerTrackerService: DrawerTrackerService,
    private ros: ResizeObserverService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private customUserModernizationService: CustomUserModernizationService, // do not remove this; it will initialize the user modernization verification.
    private sanitizer: DomSanitizer,
  ) {
    this.isLoading = this.portfolioDataService.getLoading();
    this.isFailure = this.portfolioDataService.getFailure();
    this.showPWHeader = this.portfolioDataService.getShowPWHeader();
    this.drawerHelpContentUrl = environment.AEMHelpContentUrl;

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.windowRef.nativeWindow?.scrollTo({ top: 0, left: 0 });
        this.loggerService.info(
          {
            message: 'PageLoadSuccess',
            path: this.sanitizer.sanitize(SecurityContext.URL, event.url) || '',
          },
          LoggerCode.PAGE_LOAD,
        );
        let routes = Object.values(targetAssetMixRoutes) as Array<string>;
        this.isTargetAssetMixPage = routes.includes(event.url);
        this.currentUrl = event.url;
        if (this.autoCloseDrawer()) {
          if (this.focusPanelDrawer?.isOpen) {
            this.shouldFocusPanelOpen = true;
            this.closeFocusPanel(null);
          }
        } else {
          if (this.shouldFocusPanelOpen && !this.isPageHelpOpen) {
            this.focusPanelDrawer?.openDrawer();
          }
        }
      });

    window.addEventListener('load', (event) => {
      this.loggerService.info(
        {
          message: 'Portfolio watch load duration',
          duration: event.timeStamp,
        },
        LoggerCode.PORTFOLIO_WATCH_LOADED_DURATION,
      );
    });
  }

  autoCloseDrawer(): boolean {
    const excludeUrls = [
      '/target-mix-custom-view',
      '/tester-tool',
      '/target-mix-about',
      '/target-mix-questionnaire',
      '/suggested-target-asset-mix',
    ];
    if (this.router.url.startsWith('/hypothetical')) {
      excludeUrls.push(this.currentUrl);
    }
    return excludeUrls.includes(this.currentUrl);
  }

  ngOnInit() {
    this.updateSpoid();
    this.setRandomNum();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Update the title based on the current route
      const newTitle = this.getTitleForRoute(event.urlAfterRedirects);
      this.titleService.setTitle(newTitle);
    });
  }
  ngAfterViewInit() {
    this.pageHelpDrawerTrackerService.setDrawer(this.pageHelpDrawer);
    this.pageHelpDrawerTrackerService.setDrawerArticlesMapper(DrawerPageSortMapper);
    this.ros
      .observe(this.fpDrawerElRef?.nativeElement)
      .pipe(takeUntil(this._destroy))
      .subscribe((coords: DOMRectReadOnly) => {
        this.resizeHandler(coords);
      });
    this.pageHelpDrawerTrackerService.currentDrawerStatus$.subscribe((value) => {
      this.isPageHelpOpen = value;
      if (value && this.focusPanelDrawer?.isOpen) {
        this.shouldFocusPanelOpen = true;
        this.closeFocusPanel(null);
      }
      if (!value && this.shouldFocusPanelOpen && !this.autoCloseDrawer()) {
        setTimeout(() => {
          this.shouldFocusPanelOpen = false;
          this.focusPanelDrawer?.openDrawer();
        }, 205);
      }
    });
    setTimeout(() => {
      if (!this.focusPanelMFELoaded) {
        this.loggerService.error(
          { message: 'Focus Panel MFE failed to load' },
          LoggerCode.FOCUS_PANEL_MFE_ERROR,
        );
      }
    }, 4000);
  }

  getTitleForRoute(url: string): string {
    switch (url) {
      case '/':
        return 'Portfolio Analysis | Vanguard ';
      case '/asset-mix':
        return 'Asset Mix | Vanguard';
      case '/stock-analysis/market-capitalization':
        return 'Stock Analysis | Vanguard';
      case '/bond-analysis/credit-quality':
        return 'Bond Analysis | Vanguard';
      case '/costs/mutual-fund-costs':
        return 'Investment Costs | Vanguard';
      case '/tester-tool':
        return 'Portfolio Tester | Vanguard';
      default:
        return 'Portfolio Analysis | Vanguard';
    }
  }

  resizeHandler(coords: DOMRectReadOnly) {
    const { width } = coords;
    if (width < this.fpDrawerThreshold) {
      this.isFpDrawerModal = true;
      this.fpDawerSticky = false;
      this.fpDrawerWidth = `${width}px`;
    } else {
      this.fpDawerSticky = true;
      this.isFpDrawerModal = false;
      this.fpDrawerWidth = '392px';
    }
  }

  ngOnDestroy(): void {
    this.ros.unobserve(this.fpDrawerElRef?.nativeElement);
    this._destroy.complete();
  }

  navigateToTesterPage() {
    this.router.navigate(['/tester-tool']);
  }

  /*
  Used for Medallia
   */
  private updateSpoid(): void {
    const spoid = this.meta.getTag('name="DCSext.sPoid"')?.content;
    const window = this.windowRef.nativeWindow;
    window.spoid = spoid ? spoid : -1;
  }

  private setRandomNum(): void {
    const randomNumber = Math.floor(Math.random() * 4) + 1;
    const window = this.windowRef.nativeWindow;
    window.randomNum = randomNumber ? randomNumber : -1;
  }

  handleOpenFpDrawer(): void {
    this.pageHelpDrawerTrackerService.closeDrawer();
    setTimeout(() => {
      this.focusPanelDrawer?.openDrawer();
      this.adobeAnalyticsService.CreateCTATrigger(
        'Secure Site Portfolio Watch',
        'Focus Panel Show Button',
        'Button',
      );
    }, 230);
  }

  getGroupData(event: any): void {
    this.customDisplayName = event.detail.displayName;
    this.portfolioDataService.setSelectedAccountGroupDetails({
      GroupName: event.detail.viewName,
      ViewID: event.detail.clientViewId,
      IsGroup: true,
    });
    this.portfolioDataService.loadChartDataPayload();
    if (this.isFpDrawerModal) {
      this.closeFocusPanel(null);
    }
  }

  checkFpEvent(event: any): void {
    switch (event.detail.EventName) {
      case 'FocusPanelClose':
        this.closeFocusPanel(event.detail);
        this.shouldFocusPanelOpen = false;
        break;
      case 'defaultsLoaded':
        this.FPConfig = event.detail;
        this.checkFocusPanelDefault(this.FPConfig?.isDefault());
        break;
      case 'FocusPanelDataError':
        this.loggerService.error(
          { message: 'Focus Panel Data Failure' },
          LoggerCode.FOCUS_PANEL_DATA_ERROR,
        );
        break;
      case 'FocusPanelMFELoaded':
        this.focusPanelMFELoaded = true;
        break;
      case 'FocusPanelGroupSelected':
        if (event.detail.analyticsData) {
          this.adobeAnalyticsService.CreateCTATrigger(
            event.detail.analyticsData.location,
            event.detail.analyticsData.ctaName,
            event.detail.analyticsData.ctaType,
          );
        }
        break;
      default:
        return;
    }
  }

  checkFocusPanelDefault(isDefaultOpen: boolean) {
    const mediaQuery = window.matchMedia(`(min-width: ${this.fpDrawerThreshold}px)`);
    if (
      mediaQuery.matches &&
      isDefaultOpen &&
      !this.autoCloseDrawer()
    ) {
      this.focusPanelDrawer?.openDrawer();
    }
  }

  onFpDrawerStateChange($drawerState) {
    this.isFocusPanelOpen = $drawerState.isDrawerOpen;
  }

  closeFocusPanel(data: any) {
    this.focusPanelDrawer?.closeDrawer();
    if (this.isFpDrawerModal) {
      this.focusPanelDrawer?.handleDialogClose();
    }

    if (data?.analyticsData) {
      this.adobeAnalyticsService.CreateCTATrigger(
        data.analyticsData.location,
        data.analyticsData.ctaName,
        data.analyticsData.ctaType,
      );
    }
  }
}
