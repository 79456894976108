import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { AdobeExperienceManagerService } from 'src/app/services/adobe-experience-manager/adobe-experience-manager.service';

@Pipe({
  name: 'getFromAEM',
})
export class GetFromAEMPipe implements PipeTransform {
  isEnableAEMGatekeeper$: Observable<boolean>;
  information: string;

  constructor(
    private readonly aemService: AdobeExperienceManagerService,
  ) { }

  transform(value: string, ...args: any[]): string | null {
    return this.aemService.translate(value, ...args);
  }
}