import { Component, Input, OnInit } from '@angular/core';
import { GatekeeperService } from 'src/app/services/gate-keeper/gatekeeper.service';

@Component({
  selector: 'PW1-investment-costs-disclaimer-accordion',
  templateUrl: './investment-costs-disclaimer-accordion.component.html',
  styleUrls: ['./investment-costs-disclaimer-accordion.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'investment-costs-disclaimer-accordion c11n-space-stack-3x',
  },
})
export class InvestmentCostsDisclaimerAccordionComponent implements OnInit {
  @Input() printView: boolean = false;
  isEnabledAEMGatekeeper: boolean;

  constructor(private readonly gatekeeperService: GatekeeperService) {}
  
  ngOnInit(): void {
    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isAEMEnabled) => {
      this.isEnabledAEMGatekeeper = isAEMEnabled;
    });
  }

  GetLinkUrl(content: string) {
    if (content) {
      return content.split(' : ')[1];
    }
    return null;
  }

  GetLinkText(content: string) {
    if (content) {
      return content.split(' : ')[0];
    }
    return null;
  }
}
