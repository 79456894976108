<div *ngIf="chartData" class="tab-table">
  <c11n-tabs
    activeItem="market-capitalization"
    [hasAnimation]="true"
    (activeItemChange)="updateUrl($event)"
  >
    <c11n-tab-panel labelText="Market capitalization" role="link" id="market-capitalization">
      <PW1-hypothetical-market-cap-table
        [chartData]="chartData"
        [hypotheticalData]="hypotheticalData"
      ></PW1-hypothetical-market-cap-table>
    </c11n-tab-panel>
    <c11n-tab-panel labelText="Investment style" role="link" id="investment-style">
      <PW1-hypothetical-investment-style-table
        [chartData]="chartData"
        [hypotheticalData]="hypotheticalData"
      ></PW1-hypothetical-investment-style-table>
    </c11n-tab-panel>
    <c11n-tab-panel labelText="Industry sectors" role="link" id="industry-sectors">
      <PW1-hypothetical-industry-sectors-table
        [chartData]="chartData"
        [hypotheticalData]="hypotheticalData"
      ></PW1-hypothetical-industry-sectors-table>
    </c11n-tab-panel>
    <c11n-tab-panel labelText="International regions" role="link" id="international-regions">
      <PW1-hypothetical-international-regions-table
        [chartData]="chartData"
        [hypotheticalData]="hypotheticalData"
      ></PW1-hypothetical-international-regions-table>
    </c11n-tab-panel>
  </c11n-tabs>
</div>
