import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'PW1-table-view-controls',
  templateUrl: './table-view-controls.component.html',
  styleUrls: ['./table-view-controls.component.scss'],
})
export class TableViewControlsComponent implements OnInit {
  @Output() tableViewChanged = new EventEmitter<boolean>();
  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      viewByHoldings: ['false']
    });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      viewByHoldings: new FormControl('false'),
    });
    this.tableViewChanged.emit(false);
    this.form.get('viewByHoldings').valueChanges.subscribe(value => {
      this.tableViewChanged.emit(value === 'true');
      });
  }

  setViewByHoldings(value: boolean): void {
    this.form.get('viewByHoldings').setValue(value.toString());
  }
  
  radioChange(event): void {
    const value = event.inputValue;
    this.tableViewChanged.emit(value === 'true');
  }
}
