<div #content="pwContent" pwContent *ngIf="chartData" class="tab-table modernizedTabs">
  <c11n-tabs
    activeItem="mutual-fund-costs"
    [hasAnimation]="true"
    (activeItemChange)="updateUrl($event)"
  >
    <c11n-tab-panel
      id="mutual-fund-costs"
      [labelText]="content['PWCosts_DetailsCard_MutualFundCosts_MutualFundCosts'] | getFromAEM"
      role="link"
    >
      <mutual-fund-table [chartData]="chartData"></mutual-fund-table>
    </c11n-tab-panel>
    <c11n-tab-panel
      id="tax-efficiency"
      [labelText]="content['PWCosts_DetailsCard_TaxEfficiency_TaxEfficiency'] | getFromAEM"
      role="link"
    >
      <tax-efficiency-table [chartData]="chartData"></tax-efficiency-table>
    </c11n-tab-panel>
  </c11n-tabs>
</div>
