import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InternationalDomesticHoldingsComponent } from './components/international-domestic-holdings/international-domestic-holdings.component';
import { TargetMixAboutComponent } from './pages/target-mix-about/target-mix-about.component';
import { AssetAllocationComponent } from './pages/asset-allocation/asset-allocation.component';
import { BondAnalysisComponent } from './pages/bond-analysis/bond-analysis.component';
import { CostsRiskComponent } from './pages/costs-risk/costs-risk.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { StockAnalysisComponent } from './pages/stock-analysis/stock-analysis.component';
import { SuggestedTargetAssetMixComponent } from './pages/suggested-target-asset-mix/suggested-target-asset-mix.component';
import { TargetAllocationCustomViewComponent } from './pages/target-allocation-custom-view/target-allocation-custom-view.component';
import { TesterToolComponent } from './components/tester-tool-components/tester-tool/tester-tool.component';
import { HypotheticalOverviewComponent } from './components/tester-tool-components/hypothetical-overview/hypothetical-overview.component';
import { HypotheticalAssetAllocationComponent } from './components/tester-tool-components/hypothetical-asset-allocation/hypothetical-asset-allocation.component';
import { HypotheticalBondAnalysisComponent } from './components/tester-tool-components/hypothetical-bond-analysis/hypothetical-bond-analysis.component';
import { HypotheticalStockAnalysisComponent } from './components/tester-tool-components/hypothetical-stock-analysis/hypothetical-stock-analysis.component';

import { TesterToolGuard } from './components/tester-tool-components/tester-tool-guard/tester-tool-guard';
import { HypotheticalCostsRiskComponent } from './components/tester-tool-components/hypothetical-cost-risk/hypothetical-costs-risk.component';
// import { PortfolioHealthCheckPageComponent } from './pages/portfolio-health-check-page/portfolio-health-check-page.component';
// import { RebalancingYourPortfolioComponent } from './pages/portfolio-health-check-page/rebalancing-your-portfolio/rebalancing-your-portfolio.component';
// import { NoGoalsComponent } from './pages/portfolio-health-check-page/no-goals/no-goals.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
    data: {
      pageId: 'portfolio-watch',
      siteSection1: 'portfolio-overview',
      siteSection2: 'none',
    },
  },
  // {
  //   path: 'portfolio-health-check',
  //   data: {
  //     pageId: 'portfolio-health-check',
  //   },
  //   children: [
  //     {
  //       path: 'no-goals',
  //       component: NoGoalsComponent,
  //       data: { pageId: 'no-goals' },
  //     },
  //     {
  //       path: 'rebalancing-your-portfolio',
  //       component: RebalancingYourPortfolioComponent,
  //       data: { pageId: 'rebalancing-your-portfolio' },
  //     },
  //     {
  //       path: '',
  //       component: PortfolioHealthCheckPageComponent,
  //       pathMatch: 'full',
  //     },
  //   ],
  // },
  {
    path: 'asset-mix',
    component: AssetAllocationComponent,
    data: { pageId: 'asset-mix', siteSection1: 'asset-mix', siteSection2: 'none' },
  },
  {
    path: 'stock-analysis',
    data: { pageId: 'stock-analysis' },
    children: [
      {
        path: 'market-capitalization',
        component: StockAnalysisComponent,
        data: { tabId: 0, siteSection1: 'stock-analysis', siteSection2: 'market-capitalization' },
      },
      {
        path: 'investment-style',
        component: StockAnalysisComponent,
        data: { tabId: 1, siteSection1: 'stock-analysis', siteSection2: 'investment-style' },
      },
      {
        path: 'industry-sectors',
        component: StockAnalysisComponent,
        data: { tabId: 2, siteSection1: 'stock-analysis', siteSection2: 'industry-sectors' },
      },
      {
        path: 'international-regions',
        component: StockAnalysisComponent,
        data: { tabId: 3, siteSection1: 'stock-analysis', siteSection2: 'international-regions' },
      },
      {
        path: 'domestic-vs-international',
        component: InternationalDomesticHoldingsComponent,
        data: {
          pageId: 'stock-domestic-vs.-international',
          subcard: 'stock',
          siteSection1: 'stock-analysis',
          siteSection2: 'domestic-vs-international',
        },
      },
      { path: '', redirectTo: 'market-capitalization', pathMatch: 'full' },
    ],
  },
  {
    path: 'bond-analysis',
    data: { pageId: 'bond-analysis' },
    children: [
      {
        path: 'credit-quality',
        component: BondAnalysisComponent,
        data: { tabId: 0, siteSection1: 'bond-analysis', siteSection2: 'credit-quality' },
      },
      {
        path: 'interest-rate-sensitivity',
        component: BondAnalysisComponent,
        data: {
          tabId: 1,
          siteSection1: 'bond-analysis',
          siteSection2: 'interest-rate-sensitivity',
        },
      },
      {
        path: 'nine-box',
        component: BondAnalysisComponent,
        data: { tabId: 2, siteSection1: 'bond-analysis', siteSection2: 'nine-box' },
      },
      {
        path: 'domestic-vs-international',
        component: InternationalDomesticHoldingsComponent,
        data: {
          pageId: 'bond-domestic-vs.-international',
          subcard: 'bond',
          siteSection1: 'bond-analysis',
          siteSection2: 'domestic-vs-international',
        },
      },
      { path: '', redirectTo: 'credit-quality', pathMatch: 'full' },
    ],
  },
  {
    path: 'costs',
    data: { pageId: 'investment-costs' },
    children: [
      {
        path: 'mutual-fund-costs',
        component: CostsRiskComponent,
        data: { tabId: 0, siteSection1: 'costs', siteSection2: 'mutual-fund-costs' },
      },
      {
        path: 'tax-efficiency',
        component: CostsRiskComponent,
        data: { tabId: 1, siteSection1: 'costs', siteSection2: 'tax-efficiency' },
      },
      { path: '', redirectTo: 'mutual-fund-costs', pathMatch: 'full' },
    ],
  },
  {
    path: 'target-mix-questionnaire',
    component: QuestionnaireComponent,
    data: {
      pageId: 'target-mix-questionnaire',
      siteSection1: 'target-mix-questionnaire',
      siteSection2: 'none',
    },
  },
  {
    path: 'suggested-target-asset-mix',
    component: SuggestedTargetAssetMixComponent,
    data: {
      pageId: 'suggested-target-asset-mix',
      siteSection1: 'suggested-target-asset-mix',
      siteSection2: 'none',
    },
  },
  {
    path: 'target-mix-custom-view',
    component: TargetAllocationCustomViewComponent,
    data: {
      pageId: 'target-mix-custom-view',
      siteSection1: 'target-mix-custom-view',
      siteSection2: 'none',
    },
  },
  {
    path: 'target-mix-about',
    component: TargetMixAboutComponent,
    data: {
      pageId: 'target-mix-about',
      siteSection1: 'target-mix-about',
      siteSection2: 'none',
    },
  },
  {
    path: 'tester-tool',
    component: TesterToolComponent,
    data: {
      pageId: 'portfolio-tester',
      siteSection1: 'tester-tool',
      siteSection2: 'none',
    },
  },
  {
    path: 'hypothetical-overview',
    component: HypotheticalOverviewComponent,
    data: {
      pageId: 'hypothetical-overview',
      siteSection1: 'hypothetical-overview',
      siteSection2: 'none',
    },
    canActivate: [TesterToolGuard],
  },
  {
    path: 'hypothetical-asset-mix',
    component: HypotheticalAssetAllocationComponent,
    data: {
      pageId: 'hypothetical-asset-mix',
      siteSection1: 'hypothetical-asset-mix',
      siteSection2: 'none',
    },
    canActivate: [TesterToolGuard],
  },
  {
    path: 'hypothetical-bond-analysis',
    data: {
      pageId: 'hypothetical-bond-analysis',
    },
    canActivate: [TesterToolGuard],
    children: [
      {
        path: 'credit-quality',
        component: HypotheticalBondAnalysisComponent,
        data: {
          tabId: 0,
          siteSection1: 'hypothetical-bond-analysis',
          siteSection2: 'credit-quality',
        },
      },
      {
        path: 'interest-rate-sensitivity',
        component: HypotheticalBondAnalysisComponent,
        data: {
          tabId: 1,
          siteSection1: 'hypothetical-bond-analysis',
          siteSection2: 'interest-rate-sensitivity',
        },
      },
      {
        path: 'nine-box',
        component: HypotheticalBondAnalysisComponent,
        data: { tabId: 2, siteSection1: 'hypothetical-bond-analysis', siteSection2: 'nine-box' },
      },
      { path: '', redirectTo: 'credit-quality', pathMatch: 'full' },
    ],
  },
  {
    path: 'hypothetical-stock-analysis',
    data: {
      pageId: 'hypothetical-stock-analysis',
    },
    canActivate: [TesterToolGuard],
    children: [
      {
        path: 'market-capitalization',
        component: HypotheticalStockAnalysisComponent,
        data: {
          tabId: 0,
          siteSection1: 'hypothetical-stock-analysis',
          siteSection2: 'market-capitalization',
        },
      },
      {
        path: 'investment-style',
        component: HypotheticalStockAnalysisComponent,
        data: {
          tabId: 1,
          siteSection1: 'hypothetical-stock-analysis',
          siteSection2: 'investment-style',
        },
      },
      {
        path: 'industry-sectors',
        component: HypotheticalStockAnalysisComponent,
        data: {
          tabId: 2,
          siteSection1: 'hypothetical-stock-analysis',
          siteSection2: 'industry-sectors',
        },
      },
      {
        path: 'international-regions',
        component: HypotheticalStockAnalysisComponent,
        data: {
          tabId: 3,
          siteSection1: 'hypothetical-stock-analysis',
          siteSection2: 'international-regions',
        },
      },
      { path: '', redirectTo: 'market-capitalization', pathMatch: 'full' },
    ],
  },
  {
    path: 'hypothetical-costs',
    data: {
      pageId: 'hypothetical-investment-costs',
    },
    canActivate: [TesterToolGuard],
    children: [
      {
        path: 'mutual-fund-costs',
        component: HypotheticalCostsRiskComponent,
        data: { tabId: 0, siteSection1: 'hypothetical-costs', siteSection2: 'mutual-fund-costs' },
      },
      {
        path: 'tax-efficiency',
        component: HypotheticalCostsRiskComponent,
        data: { tabId: 1, siteSection1: 'hypothetical-costs', siteSection2: 'tax-efficiency' },
      },
      { path: '', redirectTo: 'mutual-fund-costs', pathMatch: 'full' },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
