<section #content="pwContent" pwContent id="costs-risk-content" tabindex="-1">
  <PW1-hypothetical-card
    [backButtonLabel]="content['TesterTool_HypotheticalCosts_BackToOverview'] | getFromAEM"
    [title]="content['TesterTool_HypotheticalCostsCard_Title'] | getFromAEM"
    sectionLabel="Investment costs"
  >
    <ng-container>
      <account-group-assets></account-group-assets>
      <div class="bar-chart-wrapper">
        <div class="currentChart" *ngIf="hasLoadedChartData">
          <bar-chart
            [chartData]="chartDataModern"
            [industryChartId]="'testerToolCostPageActualIndustryChartId'"
            [vanguardChartId]="'testerToolCostPageActualVanguardChartId'"
            [yourCostsChartId]="'testerToolCostPageActualYourCostsChartId'"
            [isFullWidth]="false"
          ></bar-chart>
        </div>
        <div class="modernizedHypotheticalChart" *ngIf="hasLoadedHypotheticalChartData">
          <bar-chart
            [chartData]="hypotheticalChartDataModern"
            [industryChartId]="'testerToolCostPageHypotheticalIndustryChartId'"
            [vanguardChartId]="'testerToolCostPageHypotheticalVanguardChartId'"
            [yourCostsChartId]="'testerToolCostPageHypotheticalYourCostsChartId'"
            [isFullWidth]="false"
          >
          </bar-chart>
        </div>
      </div>
      <PW1-investment-costs-disclaimer-accordion></PW1-investment-costs-disclaimer-accordion>
      <PW1-hypothetical-cost-risk-tab-table
        class="cost-risk-tab-table"
      ></PW1-hypothetical-cost-risk-tab-table>
      <PW1-disclaimer
        contentTag="PWCosts_Disclaimer_Body"
        headerTag="PWCosts_Disclaimer_Header"
        linkTag="PWCosts_Disclaimer_Link_Vanguard"
      ></PW1-disclaimer>
    </ng-container>
  </PW1-hypothetical-card>
</section>
