<div #content="pwContent" pwContent class="tableComponentWrapper">
  <PW1-alert-notifications [pageName]="'/stock-analysis/investment-style'"></PW1-alert-notifications>
  <div class="tableHeaderWrapper">
    <div class="titleAndExport">
      <div class='tableTitle'>
        <h2 class="accessibility">
          <span [innerHTML]="content['PWStocks_DetailsCard_InvestmentStyle'] | getFromAEM"></span>
          <button id="helpInvestmentStyle" class="icon icon-question-mark-black" (click)="openInvestmentStyleDrawer()"
            aria-label="Help Button"></button>

          <PW1-export-to-csv *ngIf="viewByHoldings" [parsableArray]="generateTableDataByHoldingsModernized()"
            [fileName]="'StockInvestmentStyleData'" [modernized]="true">
          </PW1-export-to-csv>
        </h2>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="domesticDisclaimer" 
      [innerHTML]="content['PWStocks_DetailsCard_InvestmentStyle_DomesticDisclaimer'] | getFromAEM">
    </div>
    <div class="spacer"></div>
    <PW1-table-view-controls (tableViewChanged)="changeTableView($event)"></PW1-table-view-controls>
  </div>
  <ng-container *ngIf="loaded && !viewByHoldings">
    <table class="c11n-table c11n-table--medium">
      <thead>
        <tr class="c11n-table__tr" scope="row">
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">
            <strong>{{content['PWStocks_DetailsCard_InvestmentStyle_Headers_Size'] | getFromAEM}}</strong>
          </th>
          <th scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-right">
            <strong>{{content['PWStocks_DetailsCard_InvestmentStyle_Headers_USStockPortfolio'] | getFromAEM}}</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="c11n-table__tr" *ngFor="let tableRow of tableData"
          [ngClass]="{'totalRow': tableRow.size === 'Total'}" scope="row">
          <td scope="col" class="c11n-table__td c11n-table__tbody-th c11n-table__td--align-left">{{tableRow.size}}</td>
          <td scope="col" class="c11n-table__td c11n-table__td--align-right">{{tableRow.yours}}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-container *ngIf="loaded && viewByHoldings">
    <PW1-holdings-table [tableData]="holdingsTableDataModernized"></PW1-holdings-table>
  </ng-container>
</div>