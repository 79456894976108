import { Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {
  ModernizedStackedBarChartData,
  ModernizedStockColors,
} from '../../models/chart-models/modernized-stacked-bar-chart';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'stock-dashboard',
  templateUrl: './stock-dashboard.component.html',
  styleUrls: ['./stock-dashboard.component.scss'],
})
export class StockDashboardComponent implements OnInit, AfterViewInit {
  @Input() isFullWidth: boolean;
  @Input() chartId: string;

  stockPercentage: number;
  actualStockChartData: ModernizedStackedBarChartData;

  tabCount: number = 0;

  constructor(
    private portfolioDataService: PortfolioDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      this.stockPercentage = response.assetTypes.chart.stocks;
      this.actualStockChartData = {
        chartId: this.chartId,
        chartLabel: null,
        chartSlices: [
          {
            name: 'domestic',
            label: 'Domestic',
            value: response.stockDomesticInternational.chart.domestic,
            color: ModernizedStockColors.getColorMap().get('domestic'),
          },
          {
            name: 'international',
            label: 'International',
            value: response.stockDomesticInternational.chart.international,
            color: ModernizedStockColors.getColorMap().get('international'),
          },
          {
            name: 'uncategorized',
            label: 'Other',
            value: response.stockDomesticInternational.chart.uncategorized,
            color: ModernizedStockColors.getColorMap().get('uncategorized'),
          },
        ],
        usesMasks: false,
      };
    });
  }

  ngAfterViewInit(): void {
    this.addTabOutDrawerListener();
  }

  addTabOutDrawerListener(): void {
    const drawerElement = document.getElementById('rt-drawer');
    if (drawerElement) {
      drawerElement.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          this.tabCount++;
          if (this.tabCount > 3) {
            this.handleDrawerClose();
            this.tabCount = 0; // Reset the tab count
          }
        }
      });

      drawerElement.addEventListener('focusout', () => {
        setTimeout(() => {
          if (!drawerElement.contains(document.activeElement)) {
            this.handleDrawerClose();
            this.tabCount = 0; // Reset the tab count
          }
        }, 0);
      });
      this.drawerTrackerService.closeDrawer();
    }
  }

  setFocusToHelpStockDashboard(): void {
    const helpStockDashboard = document.getElementById('helpStockDashboard');
    if (helpStockDashboard) {
      setTimeout(() => {
        (helpStockDashboard as HTMLElement).focus();
      }, 750);
    }
  }

  handleDrawerClose(): void {
    this.setFocusToHelpStockDashboard();
    const drawerElement = document.getElementById('rt-drawer');
    if (drawerElement) {
      this.drawerTrackerService.closeDrawer();
    }
  }

  trackDomesticVsInternationalLink() {
    this.adobeAnalyticsService.clickedOnDomesticVsInternational('Stock analysis');
  }
  openStockAnalysisDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.DOMESTIC_VS_INTERNATIONAL_STOCKS);
  }
}
