<div #content="pwContent" pwContent>
  <ng-container *ngIf="!printView; else printDisclaimer">
    <c11n-accordion
      [labelText]="content['PWCosts_AccordionDisclaimer_Header'] | getFromAEM"
      iconPosition="leading"
      accordionId="investment-costs-accordion"
      labelWeight="bold"
      size="large"
      [isExpanded]="false"
      [headingLevel]="2"
    >
      <div accordion-body>
        <div class="c11n-accordion-story-content">
          <div
            *ngIf="!isEnabledAEMGatekeeper"
            class="c11n-text-md--crop c11n-link--reinforced"
            [innerHTML]="
              content['PWCosts_AccordionDisclaimer_Body'] | getFromAEM
                | aemlink
                  : GetLinkText(content['PWCosts_Disclaimer_Link_Vanguard'] | getFromAEM)
                  : GetLinkUrl(content['PWCosts_Disclaimer_Link_Vanguard'] | getFromAEM)
            "
          ></div>
          <div
            *ngIf="isEnabledAEMGatekeeper"
            class="c11n-text-md--crop c11n-link--reinforced"
          >
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_1'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_2'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_3'] | getFromAEM }}</p>
            <p class="c11n-text-md c11n-link--reinforced">
              {{(content['PWCosts_AccordionDisclaimer_Body_4'] | getFromAEM).substring(0, 6)}} 
              <a c11n-link class="link" href="https://investor.vanguard.com" [variant]="'primary-reinforced'" [noWrap]="'true'">
                {{content['PWCosts_Disclaimer_2_Link'] | getFromAEM}}
              </a>
              {{(content['PWCosts_AccordionDisclaimer_Body_4'] | getFromAEM).substring(35)}}
            </p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_5'] | getFromAEM }} {{ content['PWCosts_AccordionDisclaimer_Body_6'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_7'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_8'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_9'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_10'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_11'] | getFromAEM }}</p>
          </div>
        </div>
      </div>
    </c11n-accordion>
  </ng-container>
  <ng-template #printDisclaimer>
    <div class="print-disclaimer__header">
      <h4
        class="c11n-text-md-bold c11n-space-stack-2x c11n-space-stack--force"
        [innerHTML]="content['PWCosts_AccordionDisclaimer_Header'] | getFromAEM"
      ></h4>
    </div>
    <div class="print-disclaimer__body">
      <div
        *ngIf="!isEnabledAEMGatekeeper"
        class="c11n-text-md--crop c11n-space-stack-2x c11n-space-stack--force"
        [innerHTML]="content['PWCosts_AccordionDisclaimer_Body'] | getFromAEM"
      ></div>
      <div *ngIf="isEnabledAEMGatekeeper" class="c11n-text-md--crop c11n-space-stack-2x c11n-space-stack--force">
        <p>{{ content['PWCosts_AccordionDisclaimer_Body_1'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_2'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_3'] | getFromAEM }}</p>
            <p class="c11n-text-md c11n-link--reinforced">
              {{(content['PWCosts_AccordionDisclaimer_Body_4'] | getFromAEM).substring(0, 6)}} 
              <a c11n-link class="link" href="https://investor.vanguard.com" [variant]="'primary-reinforced'" [noWrap]="'true'">
                {{content['PWCosts_Disclaimer_2_Link'] | getFromAEM}}
              </a>
              {{(content['PWCosts_AccordionDisclaimer_Body_4'] | getFromAEM).substring(35)}}
            </p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_5'] | getFromAEM }} {{ content['PWCosts_AccordionDisclaimer_Body_6'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_7'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_8'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_9'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_10'] | getFromAEM }}</p>
            <p>{{ content['PWCosts_AccordionDisclaimer_Body_11'] | getFromAEM }}</p>
      </div>
    </div>
  </ng-template>
</div>
