<div #content="pwContent" pwContent>
    <div>
        <PW1-accordion [title]="content['PWAsset_DetailsCard_Accordion_HoldingsByAssetType'] | getFromAEM"
            [accountBalance]="holdingsTableDataModernized.holdingsTotal">
            <div class="exportLink">
                <PW1-export-to-csv [parsableArray]="holdingsTableDataModernized"
                    [modernized]="true"></PW1-export-to-csv>
            </div>
            <PW1-holdings-table [tableData]="holdingsTableDataModernized"></PW1-holdings-table>
        </PW1-accordion>
    </div>
</div>