import { AfterViewInit, Component, OnDestroy, OnInit, Input, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { ResizeObserverService } from '@vg-constellation/angular-13/core';
import { filter, Observable, skipWhile, Subject, takeUntil } from 'rxjs';
import { AccountInfo } from 'src/app/models/chart-data-response-models/account-group-details';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { GatekeeperService } from 'src/app/services/gate-keeper/gatekeeper.service';
import { LoggerService } from 'src/app/services/logger/logger.service';
import { PortfolioDataService } from 'src/app/services/portfolio-data-service/portfolio-data.service';
import { environment } from '../../../environments/environment';
import { LoggerCode } from 'src/models/logger';

@Component({
  selector: 'PW1-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('drawerCloseButton') drawerCloseButton: ElementRef;
  @ViewChild('drawer') drawer: ElementRef;
  isUserInMainPage: boolean;
  pageTitle: string = 'PWHeader_PortfolioAnalysis';
  pageId: string;
  subtitleContentNumber: string;
  $isFailure: Observable<boolean>;
  $isLoading: Observable<boolean>;
  selectedAccountGroup: AccountInfo;

  accountGroups: AccountInfo[] = [];
  customizedAccountsView: boolean;
  isEnableAEMGatekeeper: boolean;
  viewAccountGroup: AccountInfo = new AccountInfo('View/Modify Account Grouping', 'vg', false);
  createGroup: AccountInfo = new AccountInfo('Create New Group', 'cg', false);
  private destroySource: Subject<boolean> = new Subject<boolean>();
  newmanagegroupux$: Observable<boolean>;
  dropdownOpen: boolean = false;
  isFocusPanelExperienceEnabled: boolean = null;
  private readonly _destroy: Subject<void> = new Subject<void>();
  tabCount: number = 0;
  rootContainerElement: HTMLElement;
  private modernizedCustomizeView: string;
  @Input() displayName: string = '';
  @Output() printLinkClicked = new EventEmitter<any>();

  // eslint-disable-next-line max-params
  constructor(
    private loggerService: LoggerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private portfolioDataService: PortfolioDataService,
    private drawerTrackerService: DrawerTrackerService,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private gatekeeper: GatekeeperService,
    private ros: ResizeObserverService,
  ) {
    this.pageTitle = 'PWHeader_PortfolioAnalysis';
    this.$isFailure = this.portfolioDataService.getFailure();
    this.$isLoading = this.portfolioDataService.getLoading();
  }

  ngOnInit() {
    this.newmanagegroupux$ = this.gatekeeper.getModernizedManageGroupsToggle();
    // Setting page title as user navigates to different pages, and updating the selected account group in the title
    this.setTitle();
    this.pageId = this.activatedRoute.root.firstChild?.snapshot?.data['pageId'];

    // isUserInMainPage boolean is used to customize the header experience and CTAs based on which page the user is navigating.
    this.isUserInMainPage = this.router.url === '/' || this.router.url === '/?showTargetModal=true';
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.setTitle();
      this.isUserInMainPage =
        this.router.url === '/' || this.router.url === '/?showTargetModal=true';
    });
    this.portfolioDataService
      .getSelectedAccountGroupPayload()
      .pipe(skipWhile((data) => data === null))
      .subscribe((accountGroup) => {
        this.selectedAccountGroup = accountGroup;
      });
    this.portfolioDataService
      .getChartDataPayload()
      .pipe(skipWhile((data) => data === ''))
      .subscribe((data) => {
        this.customizedAccountsView = data.customizedAccountsView === true;
      });
    this.modernizedCustomizeView = environment.ModernizedCustomizeView

    this.gatekeeper.getEnableAEMGatekeeper().subscribe((isEnableAEMGatekeeper) => {
      this.isEnableAEMGatekeeper = isEnableAEMGatekeeper;
    });
  }

  ngAfterViewInit() {
    this.rootContainerElement = document.querySelector('#appHeaderWrapper');
    const containerSizeChange$ = this.ros.observe(this.rootContainerElement);

    containerSizeChange$.pipe(takeUntil(this._destroy)).subscribe((coords) => {
      this.setPrimaryCTACustomStylesFP(coords);
      this.setHeaderToolsCustomStylesFP(coords);
    });
    this.setFocusToTittleTrigger();
    this.addCloseDrawerListeners();
    this.addTabOutDrawerListener();
  }

  addCloseDrawerListeners(): void {
    // Listen for the escape key press
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.handleDrawerClose();
      }
    });

    // Listen for the close button click
    const closeButton = document.getElementById('rt-drawer-close-btn');
    if (closeButton) {
      closeButton.addEventListener('click', () => {
        this.handleDrawerClose();
      });
    }
  }

  addTabOutDrawerListener(): void {
    const drawerElement = document.getElementById('rt-drawer');
    if (drawerElement) {
      drawerElement.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
          this.tabCount++;
          if (this.tabCount > 6) {
            this.handleDrawerClose();
            this.tabCount = 0; // Reset the tab count
          }
        }
      });

      drawerElement.addEventListener('focusout', () => {
        setTimeout(() => {
          if (!drawerElement.contains(document.activeElement)) {
            this.handleDrawerClose();
            this.tabCount = 0; // Reset the tab count
          }
        }, 0);
      });
      this.drawerTrackerService.closeDrawer();
    }
  }

  handleDrawerClose(): void {
    this.setFocusToPageHelpLink();
    // Logic to collapse the drawer
    const drawerElement = document.getElementById('rt-drawer');
    if (drawerElement) {
      this.drawerTrackerService.closeDrawer();
    }
  }

  setFocusToPageHelpLink(): void {
    const pageHelpLinkElement = document.getElementById('pageHelpLink');
    if (pageHelpLinkElement) {
      setTimeout(() => {
        (pageHelpLinkElement as HTMLElement).focus();
      }, 750);
    }
  }

  setPrimaryCTACustomStylesFP(coords): void {
    const primaryCTAWrapper = document.querySelector('#primaryCTAWrapperExp');
    if (primaryCTAWrapper) {
      const containerWidth = coords.width;
      if (containerWidth <= 992) {
        primaryCTAWrapper.classList.add('primary-cta-wrapper-exp');
        primaryCTAWrapper.classList.remove('primary-cta-wrapper-exp-lg');
      } else if (containerWidth > 992) {
        primaryCTAWrapper.classList.add('primary-cta-wrapper-exp-lg');
        primaryCTAWrapper.classList.remove('primary-cta-wrapper-exp');
      }
    }
  }

  setHeaderToolsCustomStylesFP(coords): void {
    const headerToolsSection = document.querySelector('#headerToolsSection') as HTMLElement;
    if (headerToolsSection) {
      const containerWidth = coords.width;
      if (containerWidth <= 576) {
        headerToolsSection.classList.add('tools-section-align');
        headerToolsSection.classList.remove('tools-section-align-md');
      } else if (containerWidth > 576) {
        headerToolsSection.classList.add('tools-section-align-md');
        headerToolsSection.classList.remove('tools-section-align');
      }
    }
  }

  setFocusToTittleTrigger(): void {
    const titleTriggerElement = document.querySelector('#primaryCTAWrapperExp');
    if (titleTriggerElement) {
      setTimeout(() => {
        (titleTriggerElement as HTMLElement).focus();
      }, 750);
    }
  }

  setTitle() {
    this.pageId = this.activatedRoute.root.firstChild?.snapshot?.data['pageId'];
    switch (this.pageId) {
      case 'asset-mix':
        this.pageTitle = 'PWHeader_AssetAllocation_Title';
        break;

      case 'stock-analysis':
        this.pageTitle = 'PWHeader_StockAnalysis_Title';
        break;

      case 'bond-analysis':
        this.pageTitle = 'PWHeader_BondAnalysis_Title';
        break;

      case 'investment-costs':
        this.pageTitle = 'PWHeader_InvestmentCosts_Title';
        break;

      case 'portfolio-tester':
        this.pageTitle = 'PWHeader_PortfolioTester_Title';
        break;

      case 'learn-more':
        this.pageTitle =
          'PWHeader_' + this.activatedRoute.firstChild?.firstChild.snapshot?.data['pageId'];
        break;

      default:
        this.pageTitle = 'PWHeader_PortfolioAnalysis';
        break;
    }
    return;
  }

  print() {
    this.adobeAnalyticsService.clickedOnPrint();
    this.drawerTrackerService.closeDrawer();

    if (!window.onafterprint) {
      const onAfterPrint = (mql) => {
        if (!mql.matches) {
          // showStuff();

          // printing is finished => unsubscribe to avoid leaks
          if (mediaQueryList.removeEventListener) {
            mediaQueryList.removeEventListener('change', onAfterPrint);
          } else {
            mediaQueryList.removeListener(onAfterPrint);
          }
        }
        window.onfocus = null;
      };

      // a tiny polyfill for a plain onafterprint
      // to handle standard window.print() dialog events
      const mediaQueryList = window.matchMedia('print');
      if (mediaQueryList.addEventListener) {
        mediaQueryList.addEventListener('change', onAfterPrint);
      } else {
        mediaQueryList.addListener(onAfterPrint);
      }

      // if a user cancels printing in Safari's print confirmation dialog
      // then we will trigger a cleanup
      window.focus();
      window.onfocus = () => {
        onAfterPrint(mediaQueryList);
      };
    }

    this.loggerService.info(
      {
        message: 'User clicked print button',
      },
      LoggerCode.PRINT_BUTTON_CLICKED,
    );
    window.print();
  }

  openInlineDrawerPageHelp() {
    this.drawerTrackerService.openDrawer();
    this.setFocusToPageHelp();
  }

  setFocusToPageHelp(): void {
    const pageHelpElement = document.getElementById('rt-drawer');
    if (pageHelpElement) {
      setTimeout(() => {
        (pageHelpElement as HTMLElement).focus();
      }, 750);
    }
  }

  navigateBackToPortfolioWatch() {
    this.adobeAnalyticsService.clickedBackToOverview();
    this.router.navigate(['/']);
    this.portfolioDataService.setTargetMixSuccess(false);
    this.portfolioDataService.setTesterToolResetValue(true);
  }

  navigateToTesterPage() {
    this.adobeAnalyticsService.clickedOnTesterTool();
    this.router.navigate(['/tester-tool']);
  }

  navigateToPortfolioHealthCheckPage() {
    this.router.navigate(['/portfolio-health-check']);
  }

  ngOnDestroy(): void {
    this.destroySource.complete();
    this._destroy.complete();
  }

  getDisplayName() {
    return this.displayName !== '' ? this.displayName : this.selectedAccountGroup?.GroupName;
  }
}
