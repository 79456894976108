<div>
  <button class="mainBar" (click)="toggleState()" [ngClass]="{'opened': opened}" [attr.aria-expanded]="opened">
    <span *ngIf="!opened" class="icon icon-down-arrow-black"></span>
    <span *ngIf="opened" class="icon icon-up-arrow-black"></span>
      <h2 [ngClass]="{'acessibility': !isMobile, 'acessibility-mobile': isMobile}" [attr.aria-label]="title">{{title}}</h2>
    <span class="spacer"></span>
    <span class="rightLabel">{{accountBalance | currency}}</span>
  </button>
  <ng-template #accordionContent>
    <ng-content></ng-content>
  </ng-template>
  <div class="accordionContent" *ngIf="opened">
    <ng-container *ngTemplateOutlet="accordionContent"></ng-container>
  </div>
  <div class="printAccordionContent">
    <ng-container *ngTemplateOutlet="accordionContent"></ng-container>
  </div>
</div>
