<section #content="pwContent" pwContent id="bond-analysis-content" tabindex="-1">
  <PW1-hypothetical-card
    [backButtonLabel]="content['TesterTool_HypotheticalBondAnalysis_BackToOverview']"
    [title]="content['TesterTool_HypotheticalBondAnalysis'] | getFromAEM"
    sectionLabel="Bond analysis"
  >
    <ng-container>
      <account-group-assets></account-group-assets>
      <div *ngIf="hasLoadedChartData && hasLoadedHypotheticalChartData" class="chartWrapper">
        <div class="cardChart current">
          <PW1-donut-chart-component
            [id]="'testerToolBondPageActualId'"
            [dataSet]="chartData"
            [height]="188"
            [width]="188"
          ></PW1-donut-chart-component>
          <PW1-chart-legend [legendArray]="legendArrayModern"> </PW1-chart-legend>
        </div>
        <div class="cardChart hypothetical">
          <PW1-donut-chart-component
            [id]="'testerToolBondPageHypotheticalId'"
            [dataSet]="hypotheticalChartData"
            [height]="188"
            [width]="188"
          ></PW1-donut-chart-component>
          <PW1-chart-legend [legendArray]="hypotheticalLegendArrayModern"> </PW1-chart-legend>
        </div>
      </div>
      <div>
        <PW1-hypothetical-bond-analysis-tab-table></PW1-hypothetical-bond-analysis-tab-table>
      </div>
    </ng-container>
  </PW1-hypothetical-card>
</section>
