<div class="middle-content-wrapper" #content="pwContent" pwContent>
  <div class="radio-options">
    <div *ngFor="let answer of answersAndValues; index as i" class="radio-wrapper">
      <label>
        <input type="radio" [value]="content[answer.value] | getFromAEM" name="question-{{questionNumber}}">
        {{content[answer.answer] | getFromAEM}}
      </label>
    </div>
    <div class="buttons" #content="pwContent" pwContent>
      <div *ngIf="questionNumber === 1"></div>
      <button *ngIf="questionNumber > 1" type="button"
        class="c11n-button c11n-button--medium c11n-button--secondary c11n-elevation-100" (click)="emit(0)">
        <span class="c11n-button__box" [innerHTML]="content['PWQuestionnaire_Previous'] | getFromAEM">
        </span>
      </button>
      <button *ngIf="questionNumber < 11" type="button"
        class="c11n-button c11n-button--medium c11n-button--primary c11n-elevation-100" (click)="emit(1)">
        <span class="c11n-button__box" [innerHTML]="content['PWQuestionnaire_Next'] | getFromAEM">
        </span>
      </button>
      <button *ngIf="questionNumber === 11" type="button"
        class="c11n-button c11n-button--medium c11n-button--primary c11n-elevation-100" (click)="emit(2)">
        <span class="c11n-button__box" [innerHTML]="content['PWQuestionnaire_SeeResults'] | getFromAEM">
        </span>
      </button>
    </div>
  </div>
</div>
<div class="additional-content-wrapper">
  <div class="why-am-i" [innerHTML]="content['PWQuestionnaire_WhyAmIBeingAsked'] | getFromAEM"></div>
  <p [innerHTML]="content[additionalContent] | getFromAEM"></p>
  <want-help-banner></want-help-banner>
</div>