import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { GatekeeperService } from 'src/app/services/gate-keeper/gatekeeper.service';

@Component({
  selector: 'PW1-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
})
export class QuestionnaireComponent implements AfterViewInit {
  currentPage = 0;
  pages;
  userAnswers;
  error = false;
  numberOfAnswers = [5, 6, 5, 5, 5, 4, 3, 4, 5, 5, 5];
  results = [0, 0];

  isAEMGatekeeperEnabled: boolean;

  constructor(
    private router: Router,
    private portfolioDataService: PortfolioDataService,
    private route: ActivatedRoute,
    private adobeAnalyticsService: AdobeAnalyticsService,
    private readonly gatekeeperService: GatekeeperService
  ) {
    this.route.data.subscribe((data) => {
      this.adobeAnalyticsService.trackRoutingData(data);
    });

    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isAEMGatekeeperEnabled) => {
      this.isAEMGatekeeperEnabled = isAEMGatekeeperEnabled;
    });
  }

  ngAfterViewInit() {
    this.error = false;
    this.pages = document.getElementsByName('questionnaire-page');
    this.activate();
  }

  previous() {
    this.currentPage--;
    this.activate();
  }

  next() {
    if (this.validate()) {
      this.currentPage++;
      this.activate();
      this.error = false;
      window.scrollTo(0, 0);
    } else {
      this.error = true;
    }
  }

  activate() {
    for (let i = 0; i < this.pages.length; i++) {
      if (i === this.currentPage) {
        this.pages[i].style.display = 'block';
      } else this.pages[i].style.display = 'none';
    }
  }

  validate() {
    let chosenAnswer = this.pages[this.currentPage].querySelectorAll('input:checked');
    return chosenAnswer.length === 1;
  }

  submit() {
    if (this.validate()) {
      let sum = 0;
      this.error = false;
      this.userAnswers = [];
      for (let i = 0; i < this.pages.length; i++) {
        this.userAnswers.push(this.pages[i].querySelectorAll('input:checked')[0].value);
      }
      sum = this.userAnswers.reduce((a, b) => parseInt(a) + parseInt(b), 0);
      this.calc(sum);
    } else {
      this.error = true;
    }
  }

  navigateBackToPortfolioWatch() {
    this.adobeAnalyticsService.clickedBackToOverview();
    this.router.navigate(['/']);
  }

  // eslint-disable-next-line complexity
  calc(sum: number) {
    if (sum < 23) {
      this.results = [100, 0];
    } else if (sum < 29) {
      this.results = [80, 20];
    } else if (sum < 36) {
      this.results = [70, 30];
    } else if (sum < 42) {
      this.results = [60, 40];
    } else if (sum < 49) {
      this.results = [50, 50];
    } else if (sum < 55) {
      this.results = [40, 60];
    } else if (sum < 62) {
      this.results = [30, 70];
    } else if (sum < 69) {
      this.results = [20, 80];
    } else {
      this.results = [0, 100];
    }
    this.portfolioDataService.newMix = this.results;
    this.router.navigate(['suggested-target-asset-mix']);
  }
}
