<c11n-card #content="pwContent" pwContent>
    <div cardHeading class="titleWrapper">
        <div class="titleWrapper__row">
            <h3 *ngIf="!isHypotheticalData && router.url.includes('bond-analysis')" class="accessibility"
                [innerHTML]="content['PWHeader_BondAnalysis_Title'] | getFromAEM">
            </h3>
            <h3 *ngIf="!isHypotheticalData && !router.url.includes('bond-analysis')" class="accessibility"
                [innerHTML]="content['PWHeader_BondAnalysis_Title_Card'] | getFromAEM">
            </h3>
            <span *ngIf="isHypotheticalData" class="center-vertically"
                [innerHTML]="content['TesterTool_Overview_HypotheticalBondCard_Title'] | getFromAEM">
            </span>
            <p class="titleWrapper__title">
                <button class="icon icon-question-mark-black " (click)="selectSection('Bond analysis')"
                    aria-label="Bond analysis help button"></button>
            </p>
            <button cardAction #cardAction *ngIf="hasBonds" class="navigateLink seeDetails"
                aria-label='Bond Analysis see details' (click)="goToBondAnalysisPage()">
                <span [innerHTML]="content['PWOverview_BondAnalysis_SeeDetails'] | getFromAEM"></span><i
                    class="icon icon-right-arrow-blue-modernized"></i>
            </button>
        </div>
    </div>
    <div cardBody>
        <p *ngIf="!hasBonds; else bonds;" [innerHTML]="content[contentNumber] | getFromAEM"></p>
        <ng-template #bonds>
            <div [ngClass]="{'isHypothetical': isHypotheticalData}" class="modernizedContainer">
                <div class="cardChart">
                    <PW1-donut-chart-component [id]="chartId" [dataSet]="bondAnalysisChartData" [height]="188"
                        [width]="188"></PW1-donut-chart-component>
                </div>
                <div class="cardLegend">
                    <PW1-chart-legend [legendArray]="legendArrayModern"
                        [legendHeader]="legendHeader"></PW1-chart-legend>
                </div>
            </div>
        </ng-template>
    </div>
</c11n-card>