<div *ngIf="chartData" class="tab-table modernizedTabs">
  <c11n-tabs
    activeItem="credit-quality"
    [hasAnimation]="true"
    (activeItemChange)="updateUrl($event)"
  >
    <c11n-tab-panel labelText="Credit quality" id="credit-quality" role="link">
      <PW1-credit-quality-table [chartData]="chartData"></PW1-credit-quality-table>
    </c11n-tab-panel>
    <c11n-tab-panel
      labelText="Interest rate sensitivity"
      id="interest-rate-sensitivity"
      role="link"
    >
      <PW1-effective-maturity-table [chartData]="chartData"></PW1-effective-maturity-table>
    </c11n-tab-panel>
    <c11n-tab-panel labelText="Bond 9-box style" id="nine-box" role="link">
      <PW1-nine-box-component [chartData]="chartData"></PW1-nine-box-component>
    </c11n-tab-panel>
  </c11n-tabs>
</div>
