import { Component, Input, OnInit } from '@angular/core';
import {
  ModernizedBondColors,
  ModernizedStackedBarChartData,
} from '../../models/chart-models/modernized-stacked-bar-chart';
import { PortfolioDataService } from '../../services/portfolio-data-service/portfolio-data.service';
import { AllChartsResponseData } from '../../models/chart-data-response-models/all-charts-response-data';
import { Router } from '@angular/router';
import { DrawerTrackerService } from '@vanguard/pfx-components';
import { DRAWER_CONSTANTS } from 'src/app/models/drawer.constants';
import { AdobeAnalyticsService } from 'src/app/services/adobe-analytics/adobe-analytics.service';

@Component({
  selector: 'bond-dashboard',
  templateUrl: './bond-dashboard.component.html',
  styleUrls: ['./bond-dashboard.component.scss'],
})
export class BondDashboardComponent implements OnInit {
  @Input() isFullWidth: boolean;
  @Input() chartId: string;

  bondPercentage: number;
  actualBondChartData: ModernizedStackedBarChartData;

  constructor(
    private portfolioDataService: PortfolioDataService,
    private router: Router,
    private drawerTrackerService: DrawerTrackerService,
    public adobeAnalyticsService: AdobeAnalyticsService,
  ) {}

  ngOnInit() {
    this.portfolioDataService.getChartDataPayload().subscribe((response: AllChartsResponseData) => {
      this.bondPercentage = response.assetTypes.chart.bonds;
      this.actualBondChartData = {
        chartId: this.chartId,
        chartLabel: null,
        chartSlices: [
          {
            name: 'domestic',
            label: 'Domestic',
            value: response.bondDomesticInternational.chart.domestic,
            color: ModernizedBondColors.getColorMap().get('domestic'),
          },
          {
            name: 'international',
            label: 'International',
            value: response.bondDomesticInternational.chart.international,
            color: ModernizedBondColors.getColorMap().get('international'),
          },
          {
            name: 'uncategorized',
            label: 'Other',
            value: response.bondDomesticInternational.chart.uncategorized,
            color: ModernizedBondColors.getColorMap().get('uncategorized'),
          },
        ],
        usesMasks: false,
      };
    });
  }

  trackDomesticVsInternationalLink() {
    this.adobeAnalyticsService.clickedOnDomesticVsInternational('Bond analysis');
  }
  openBondAnalysisDrawer() {
    this.drawerTrackerService.setSelectSection(DRAWER_CONSTANTS.DOMESTIC_VS_INTERNATIONAL_BONDS);
  }
}
