import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { LoggerCode } from '../../../models/logger';
import { LoggerService } from '../logger/logger.service';
import { environment } from 'src/environments/environment';
import localParsedData from '../../../assets/content.json'
import { GatekeeperService } from '../gate-keeper/gatekeeper.service';

@Injectable({
  providedIn: 'root',
})
export class ContentAsAService {
  contentPayload = new BehaviorSubject<any>('');

  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private readonly gatekeeperService: GatekeeperService
  ) {
    this.loadContentPayload();
  }

  getContentPayload(): Observable<any> {
    return this.contentPayload.asObservable();
  }

  loadContentPayload() {
    this.gatekeeperService.getEnableAEMGatekeeper().subscribe((isEnableAEMGatekeeper) => {
      if (isEnableAEMGatekeeper) {
        this.loadContentFromAEMJson();
      } else {
        this.loadContentFromAEMRetailHeadless()
      }
    })
  }

  private getContentFromAEMRetailHeadless() {
    const url = environment.AEMContentUrl;
    return this.http.get(url);
  }

  private loadContentFromAEMJson() {
    try {
      const response = require('../../../assets/aem-content.json')
      this.contentPayload.next(response.content.Data.Pw1Chunk.content);
    } catch (error) {
      this.loggerService.error(
        {
          message: error.message,
          status: error.status,
        },
        LoggerCode.AEM_CONTENT_NOT_LOADED,
      );
      this.contentPayload.next(localParsedData.content.Data.Pw1Chunk.content);
    }
  }

  private loadContentFromAEMRetailHeadless() {
    this.getContentFromAEMRetailHeadless().subscribe({
      next: () => {
        this.contentPayload.next(localParsedData.content.Data.Pw1Chunk.content);
      },
      error: (error) => {
        this.loggerService.error(
          {
            message: error.message,
            status: error.status,
          },
          LoggerCode.AEM_CONTENT_NOT_LOADED,
        );
      }
    });
  }
}
